<template>
  <div class="downloadApp">
    <div class="banner">
      <h2>点滴灵犀下载中心</h2>
      <p>点滴灵犀支持各种设备下载</p>
    </div>
    <div class="tabs">
      <div @click="activeIndex = 0" :class="activeIndex == 0 ? 'active' : ''"><img v-if="activeIndex == 0" src="../assets/download/icon-pc-sel.png" alt=""><img v-else src="../assets/download/icon-pc.png" alt=""> PC端</div>
      <div @click="activeIndex = 1" :class="activeIndex == 1 ? 'active' : ''"><img v-if="activeIndex == 1" src="../assets/download/icon-mobile-sel.png" alt=""><img v-else src="../assets/download/icon-mobile.png" alt="">移动端</div>
    </div>
    <div class="tips">
      <div v-if="activeIndex == 0" class="title">多种情绪筛查系统供您使用</div>
      <div v-if="activeIndex == 1" class="title">同时为您提供多种移动应用选择</div>
      <p>针对不同行业有多种不同的选择</p>
    </div>
    <div class="pcList" v-if="activeIndex == 0">
      <div @click="down('https://oss.lingximind.com/apk/情绪压力筛查及危机预警系统-win32-x64-v211202b1.rar')">
        <img src="../assets/download/icon-down.png" class="icon-down" alt="">
        <img src="../assets/download/icon-windows.png" class="icon-windows" alt="">
        <p>情绪压力筛查及<br />危机预警系统</p>
      </div>
      <div @click="down('https://oss.lingximind.com/apk/社会心态预警与评估系统-win32-x64-v211202b1.rar')">
        <img src="../assets/download/icon-down.png" class="icon-down" alt="">
        <img src="../assets/download/icon-windows.png" class="icon-windows" alt="">
        <p>社会心态预警 <br /> 与评估系统</p>
      </div>
      <div @click="down('https://oss.lingximind.com/apk/新幸福心联-win32-x64-v220915b1.rar')">
        <img src="../assets/download/icon-down.png" class="icon-down" alt="">
        <img src="../assets/download/icon-windows.png" class="icon-windows" alt="">
        <p>幸福心联</p>
      </div>
      <div @click="down('https://oss.lingximind.com/apk/HRV%E7%9B%91%E6%B5%8B%E7%B3%BB%E7%BB%9F-win32-x64.202401.zip')">
        <img src="../assets/download/icon-down.png" class="icon-down" alt="">
        <img src="../assets/download/icon-windows.png" class="icon-windows" alt="">
        <p>灵犀体检HRV<br />监测系统</p>
      </div>
    </div>
    <div class="mobileList" v-if="activeIndex == 1">
      <div class="left" @click="down('https://oss.lingximind.com/apk/lx_team_v2.0.11_211208_release.apk')">
        <img src="../assets/download/icon-down.png" class="icon-down" alt="">
        <img src="../assets/download/icon-android.png" class="icon-phone" alt="">
        <span>Android</span>
        <p>情绪压力筛查及危机预警系统</p>
      </div>
      <div class="right">
        <div @click="down('https://apps.apple.com/cn/app/seemo%E7%8A%80%E9%99%8C/id6457200332')">
          <img src="../assets/download/icon-down.png" class="icon-down" alt="">
          <dl>
            <dt>
              <img src="../assets/download/icon-apple.png" class="icon-phone" alt="">
            </dt>
            <dd>
              <span>IOS</span>
              <p>Seemo（犀陌）App</p>
            </dd>
          </dl>
        </div>
        <div @click="down('https://oss.lingximind.com/apk/__UNI__2F3BD83__20231018100122.apk')">
          <img src="../assets/download/icon-down.png" class="icon-down" alt="">
          <dl>
            <dt>
              <img src="../assets/download/icon-android.png" class="icon-phone" alt="">
            </dt>
            <dd>
              <span>Android</span>
              <p>情绪宝 App</p>
            </dd>
          </dl>
        </div>
        <!-- <div @click="down('https://oss.lingximind.com/apk/seemo1.0.6.apk')"> -->
        <div @click="down('https://oss.lingximind.com/apk/seemo1.2.0.apk')">
          <img src="../assets/download/icon-down.png" class="icon-down" alt="">
          <dl>
            <dt>
              <img src="../assets/download/icon-android.png" class="icon-phone" alt="">
            </dt>
            <dd>
              <span>Android</span>
              <p>Seemo（犀陌）App</p>
            </dd>
          </dl>
        </div>
        <div @click="down('https://oss.lingximind.com/apk/__UNI__1EC8FAC__20231018093609.apk')">
          <img src="../assets/download/icon-down.png" class="icon-down" alt="">
          <dl>
            <dt>
              <img src="../assets/download/icon-android.png" class="icon-phone" alt="">
            </dt>
            <dd>
              <span>Android</span>
              <p>情绪宝Lite App</p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      activeIndex:0
    }
  },
  mounted(){
    if(window.document.documentElement.clientWidth < 1180){
     this.$router.push({
      path:'/Mdownload'
     })
    }
  },
  methods:{
    down(link){
      window.open(link)
    }
  }
}
</script>
<style lang="less" scoped>
.banner{
  background: url(../assets/about/banner.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  height: 500px;
  box-sizing: border-box;
  position: relative;
  margin-top: -78px;
  padding-top: 173px;
  h2{
    margin: 0;
    text-align: center;
    font-size: 44px;
    line-height: 64px;
    color: #222;
    font-weight: 400;
  }
  p{
    margin: 32px 0 0;
    font-size: 16px;
    line-height: 23px;
    color: #222;
    font-weight: 400;
    text-align: center;
  }
}
.tabs{
  margin-top: -47px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  div{
    width: 264px;
    background: #fff;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    height: 94px;
    box-shadow: 0px 6px 10px 0px rgba(0,101,255,0.302);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222222;
    font-size: 20px;
    img{
      width: 20px;
      margin-right: 8px;
    }
  }
  div.active{
    background: #0065FF;
    color: #fff;
  }
}
.tips{
  margin-top: 77px;
  .title{
    font-size: 34px;
    line-height: 49px;
    color: #011739;
    text-align: center;
    margin-bottom: 12px;
  }
  p{
    margin: 0;
    text-align: center;
    color: #798A94;
    font-size: 20px;
  }
}
.pcList{
  padding-top: 65px;
  padding-bottom: 80px;
  display: flex;
  justify-content: center;
  div{
    width: 304px;
    height: 379px;
    text-align: center;
    img.icon-down{
      width: 38px;
      margin-top: 26px;
      float: right;
      cursor: pointer;
      margin-right: 35px;
    }
    img.icon-windows{
      width: 72px;
      margin: 50px 116px 0;
    }
    p{
      font-size: 20px;
      color: #fff;
      // margin-top: 60px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  div:nth-child(1){
    background: url(../assets/download/pc-bg-1.png) no-repeat ;
    background-size: 304px 379px;
  }
  div:nth-child(2){
    background: url(../assets/download/pc-bg-2.png) no-repeat ;
   background-size: 304px 379px
  }
  div:nth-child(3){
    background: url(../assets/download/pc-bg-3.png) no-repeat ;
    background-size: 304px 379px
  }
  div:nth-child(4){
    background: url(../assets/download/pc-bg-4.png) no-repeat ;
    background-size: 304px 379px
  }
}
.mobileList{
  max-width: 1216px;
  margin: 58px auto 80px;
  display: flex;
  .left{
    background: url(../assets/download/mobile-bg-1.png) no-repeat;
    width: 375px;
    height: 445px;
    margin-left: -35px;
    background-size: 100% 100%;
    position: relative;
    text-align: center;
    .icon-down{
      position: absolute;
      width: 38px;
      height: 38px;
      top: 32px;
      right: 35px;
    }
    .icon-phone{
      width: 72px;
      height: 72px;
      margin: 84px 151px 92px;
    }
  }
  .right{
    display: flex;
    flex-wrap: wrap;
    div{
      width: 463px;
      height: 240px;
      position: relative;
      .icon-down{
        position: absolute;
        width: 38px;
        height: 38px;
        top: 32px;
        right: 35px;
      }
      dl{
        display: flex;
        margin: 64px 0 84px  64px;
        .icon-phone{
          width: 72px;
          flex-shrink: 0;
          cursor: pointer;
          height: 72px;
        }
        dd{
          margin: 0;
          margin-top: 50px;
          flex: 1;
          text-align: center;
        }
      }
        
    }
    div:nth-child(1){
      background: url(../assets/download/mobile-bg-2.png) no-repeat;
      background-size: 463px 240px;
      margin-left: -25px;
    }
    div:nth-child(2){
      background: url(../assets/download/mobile-bg-3.png) no-repeat;
      background-size: 463px 240px;
      margin-left: -25px;

    }
    div:nth-child(3){
      background: url(../assets/download/mobile-bg-4.png) no-repeat;
      margin-left: -25px;
      margin-top: -35px;
      background-size: 463px 240px;
    }
    div:nth-child(4){
      background: url(../assets/download/mobile-bg-5.png) no-repeat;
      background-size: 463px 240px;
      margin-left: -25px;
      margin-top: -35px;

    }
  }

    span{
      font-size: 14px;
      line-height: 20px;
      display: block;
      color: #6A7171;
      // margin-top: 50px;
    }
    p{
      margin: 16px 0 0;
      font-size: 20px;
      line-height: 29px;
      color: #222;
      font-weight: 700;
    }
}
</style>