<template>
  <div :class="windowWidth >= 1180 ? 'about' : 'about small'">
    <div class="banner">
      <div class="banner-main">
        <div class="left">
          <img
            src="../assets/about/banner-title.png"
            class="img-title"
            alt=""
            srcset=""
          />
          <img
            src="../assets/about/banner-text.png"
            class="img-text"
            alt=""
            srcset=""
          />
          <div class="banner-btn" @click="toPage('reservation')">
            立即预约演示
          </div>
        </div>
        <div class="right">
          <img src="../assets/about/banner-img.png" alt="" />
        </div>
      </div>
    </div>
    <div class="intro">
      <div class="intro-content">
        <h3>点滴介绍</h3>
        <h6>发现自己 · 了解自己 · 温暖他人</h6>
        <div class="intro-txt">
          <p>
            北京点滴灵犀科技有限公司成立于2018年，是一家专注于情绪健康数据分析的国家高新企业。依托国内外顶级认知心理专家团队的科研成果，结合人工智能、物联网专家团队技术，利用智能穿戴、人脸识别等工具，研发创新型情绪科技产品，多维度综合分析情绪。
          </p>
          <p>
            公司发挥国家发明专利等自有知识产权优势，将情绪健康数据分析应用于心理测试评估、心理干预等领域，为教育局、学校提供学生心理健康普测普筛服务；为医院、体检中心提供心理筛查、心检设备；为企业打造“预防+调节+干预”一体的的保障型心理健康服务体系；为多地政府搭建社会心理服务信息化平台和社会心态数据监测平台。
          </p>
          <p>
            目前，心理筛查评估日检测量达3000人次，已服务400家社会心理服务站，平台已入驻10000余名社会心理服务人才。 未来我们还将构建以“心理学生态链系统”，以犀陌APP+公众号+小程序+新媒体/抖音/小红书+线上+线下，全域布局的业态渗透各行各业。
          </p>
        </div>
      </div>
      <div class="intro-img">
        <img src="../assets/about/intro.png" alt="" />
      </div>
    </div>
    <div class="values">
      <div class="title">点滴灵犀价值观</div>
      <h5>看见情绪、传递温暖</h5>
      <div class="valuesList">
        <div class="valusBox">
          <div>
            <p>诚信</p>
            <span>诚实守信</span><span>信誉卓越</span>
          </div>
        </div>
        <div class="valusBox">
          <div>
            <p>乐观</p>
            <span>积极向上</span><span>无限希望</span>
          </div>
        </div>
        <div class="valusBox">
          <div>
            <p>担当</p>
            <span>勇往直前</span><span>兢兢业业</span>
          </div>
        </div>
        <div class="valusBox">
          <div>
            <p>平等</p>
            <span>一视同仁</span><span>平等待人</span>
          </div>
        </div>
        <div class="valusBox">
          <div>
            <p>共赢</p>
            <span>和衷共济</span><span>互利共赢</span>
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <div class="title">点滴灵犀产品地理区域部署</div>
      <h5>
        点滴灵犀合作区域覆盖全国<i>18</i>个省，其中包含<i>9</i>个省会，<i>18</i>个市
      </h5>
      <img v-if="mapShow" src="../assets/about/map1.gif" alt="" />
    </div>
    <div class="year">
      <div class="title">点滴灵犀里程碑</div>
      <h5>回顾社会心理服务体系-北京模式 十年探索历程</h5>
    </div>
    <div class="honor">
      <div class="title">点滴灵犀荣誉</div>
      <div class="tabBox">
        <div class="tabs">
          <p @click="active = 0" :class="active == 0 ? 'active' : ''">专利</p>
          <p @click="active = 1" :class="active == 1 ? 'active' : ''">
            荣誉证书
          </p>
          <p @click="active = 2" :class="active == 2 ? 'active' : ''">认证</p>
          <p @click="active = 3" :class="active == 3 ? 'active' : ''">
            兼容证明
          </p>
        </div>
        <div class="tabImg" v-if="active == 0">
          <dl>
            <dt><img src="../assets/about/rz-qxb.png" alt="" /></dt>
            <dd>情绪宝软件（小程序版）</dd>
          </dl>
          <dl>
            <dt><img src="../assets/about/rz-wjxt.png" alt="" /></dt>
            <dd>
              情绪压力筛查及
              <p>危机预警系统</p>
            </dd>
          </dl>
        </div>
        <div class="tabImg" v-if="active == 1">
          <dl>
            <dt><img src="../assets/about/ry-xy.png" alt="" /></dt>
            <dd>
              2018校园好方案
              <p>优秀案例</p>
            </dd>
          </dl>
          <dl>
            <dt><img src="../assets/about/ry-zh.png" alt="" /></dt>
            <dd>
              2019智慧教育
              <p>优秀校园好方案</p>
            </dd>
          </dl>
        </div>
        <div class="tabImg three" v-if="active == 2">
          <dl>
            <dt><img src="../assets/about/rz-zgc.png" alt="" /></dt>
            <dd>中关村高新技术企业</dd>
          </dl>
          <dl>
            <dt><img src="../assets/about/rz-gj.png" alt="" /></dt>
            <dd>国家高新技术企业</dd>
          </dl>
        </div>
        <div class="tabImg four" v-if="active == 3">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item>
              <dl>
                <dt><img src="../assets/about/jr-bl.png" alt="" /></dt>
                <dd>宝兰德产品兼容性 <p>互认证证书</p></dd>
              </dl>
              <dl>
                <dt><img src="../assets/about/jr-dm.png" alt="" /></dt>
                <dd>达梦产品兼容 <p>互认证证书</p></dd>
              </dl>
              <dl>
                <dt><img src="../assets/about/jr-dj.png" alt="" /></dt>
                <dd>大金仓兼容性 <p>认证证书</p></dd>
              </dl>
            </el-carousel-item>
            <el-carousel-item>
              <dl>
                <dt><img src="../assets/about/jr-dft.png" alt="" /></dt>
                <dd>东方通产品兼容 <p>互认证明</p></dd>
              </dl>
              <dl>
                <dt><img src="../assets/about/jr-jd.png" alt="" /></dt>
                <dd>金蝶产品兼容 <p>互认证明</p></dd>
              </dl>
              <dl>
                <dt><img src="../assets/about/jr-py.png" alt="" /></dt>
                <dd>普元产品兼容 <p>互认证证书</p></dd>
              </dl>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      windowWidth: 0,
      mapShow: false,
    };
  },
  created() {
    this.windowWidth = window.document.documentElement.clientWidth;
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (window.pageYOffset > 950) {
        this.mapShow = true;
      }
    },

    toPage(name) {
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false;
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.banner {
  background: url(../assets/about/banner.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  height: 500px;
  box-sizing: border-box;
  position: relative;
  margin-top: -58px;
  .banner-main {
    max-width: 1180px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .left {
      padding-top: 108px;
      .img-title {
        width: 417px;
        // position: absolute;
        // bottom: 250px;
        margin-bottom: 33px;
      }
      .img-text {
        width: 431px;
        margin-bottom: 38px;
        // position: absolute;
        // bottom: 176px;
      }
      .banner-btn {
        cursor: pointer;
        position: absolute;
        bottom: 87px;
        background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
        width: 190px;
        height: 52px;
        border-radius: 30px;
        text-align: center;
        line-height: 52px;
        color: #0065ff;
        font-size: 16px;
      }
    }
    .right {
      padding-top: 88px;
      box-sizing: border-box;
      img {
        width: 431px;
      }
    }
  }
}
.intro {
  padding: 85px 0 81px;
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .intro-content {
    margin-right: 60px;
    h3 {
      color: #011739;
      font-size: 34px;
      line-height: 49px;
      font-weight: 700;
      margin: 0 0 12px;
    }
    h6 {
      color: #798a94;
      font-size: 20px;
      line-height: 29px;
      font-weight: 400;
      margin: 0 0 18px;
    }
    .intro-txt {
      background: url(../assets/about/intro-bg.png) no-repeat;
      background-size: 307px auto;
      p {
        margin: 0;
        padding-top: 24px;
        font-size: 12px;
        color: #3d3d3d;
        line-height: 17px;
      }
    }
  }
  .intro-img {
    width: 572px;
    flex-shrink: 0;
    img {
      width: 572px;
    }
  }
}
.values {
  padding: 84px 0 80px;
  max-width: 1180px;
  text-align: center;
  margin: auto;
  .title {
    font-size: 34px;
    line-height: 49px;
    color: #011739;
    font-weight: 700;
  }
  h5 {
    font-size: 20px;
    font-weight: 400;
    color: #798a94;
    line-height: 29px;
    margin: 12px 0 50px;
  }
  .valuesList {
    display: flex;
    justify-content: space-between;
    div.valusBox {
      color: #fff;
      width: 217px;
      box-sizing: border-box;
      height: 470px;
      background: url(../assets/about/cx.png) no-repeat 100%;
      div {
        height: 100%;
        box-sizing: border-box;
        padding: 212px 0 0;
      }
      p {
        margin: 0 0 21px;
        font-size: 32px;
        line-height: 46px;
      }
      span {
        display: block;
        font-size: 18px;
        line-height: 26px;
      }
    }
    div.valusBox:nth-child(2) {
      background: url(../assets/about/lg.png) no-repeat 100%;
    }
    div.valusBox:nth-child(3) {
      background: url(../assets/about/dd.png) no-repeat 100%;
    }
    div.valusBox:nth-child(4) {
      background: url(../assets/about/pd.png) no-repeat 100%;
    }
    div.valusBox:nth-child(5) {
      background: url(../assets/about/gy.png) no-repeat 100%;
    }
    div.valusBox:hover {
      div {
        background: rgba(74, 143, 184, 0.9);
        animation: fadeIn 1s;
      }
      /* 应用动画 */
      p,
      span {
        transition: transform 1s;
        transform: translate(0, -37px);
      }
    }
  }
}
.map {
  width: 100%;
  text-align: center;
  padding: 84px 0 0;
  background: #e4eaef;
  .title {
    font-size: 34px;
    position: relative;
    line-height: 49px;
    color: #011739;
    font-weight: 700;
  }
  h5 {
    font-size: 20px;
    line-height: 29px;
    position: relative;
    color: #798a94;
    font-weight: 400;
    margin: 12px 0 50px;
    font-weight: 400;
    i {
      font-style: normal;
      color: #0065ff;
    }
  }
  img {
    margin-top: -204px;
    width: 100%;
  }
}
.honor {
  margin-top: -5px;
  padding: 80px 0;
  background: url(../assets/about/honor-bg.png) no-repeat #f5f9ff;
  background-size: auto 100%;
  background-position-x: center;
  .title {
    font-size: 34px;
    line-height: 49px;
    color: #011739;
    text-align: center;
    font-weight: 700;
    margin-bottom: 60px;
  }
  .tabBox {
    width: 1180px;
    height: 565px;
    margin: auto;
    padding-bottom: 56px;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
  }
  .tabs {
    display: flex;
    p {
      margin: 0;
      flex: 1;
      text-align: center;
      font-size: 20px;
      line-height: 67px;
      background: #fafafa;
      box-sizing: border-box;
      cursor: pointer;
    }
    p.active {
      background: #fff;
      border-top: 1px solid #4a8fb8;
      color: #4a8fb8;
    }
  }
  .tabImg {
    display: flex;
    dl {
      margin: 60px 0 0;
      flex: 1;
      text-align: center;
      dt {
        img {
          width: 295px;
        }
      }
      dd {
        margin: -90px 0 0;
        font-size: 16px;
        color: #333;
        line-height: 23px;
        p {
          margin: 0;
          font-size: 16px;
          color: #333;
          line-height: 23px;
        }
      }
    }
  }
  .tabImg.three {
    padding-top: 5px;
    dt {
      img {
        width: 548px;
      }
    }
    dd {
      margin-top: -246px;
    }
  }
  .tabImg.four{
    height: 565px;
    .el-carousel,.el-carousel__container{
      height: 565px !important;
      width: 100%;
      .el-carousel__item{
        height: 100%;
        display: flex;
        dt {
          margin-top: 50px;
      img {
        width: 241px;
      }
    }
    dd {
      margin-top: -90px;
    }
      }
    }
  }
}
.year{
  height: 807px;
  background: url(../assets/about/year-bg.png) no-repeat ;
  background-size: 100% auto;
  width: 100%;
  text-align: center;
  padding: 84px 0 0;
  .title {
    font-size: 34px;
    position: relative;
    line-height: 49px;
    color: #011739;
    font-weight: 700;
  }
  h5 {
    font-size: 20px;
    line-height: 29px;
    position: relative;
    color: #798a94;
    font-weight: 400;
    margin: 12px 0 50px;
    font-weight: 400;
    i {
      font-style: normal;
      color: #0065ff;
    }
  }
}
.small {
  .banner {
    padding-left: 10%;
    box-sizing: border-box;
    .banner-main .left .img-title {
      width: 70%;
    }
    .banner-main .left .img-text {
      width: 75%;
    }
    .right {
      display: none;
    }
  }
  .intro {
    width: 100%;
    padding: 20px 0 20px;
    flex-wrap: wrap;
    h3 {
      font-size: 20px;
      margin-bottom: 6px;
      line-height: 28px;
    }
    h6 {
      font-size: 14px;
      margin-bottom: 6px;
    }
    .intro-content {
      margin-right: 0;
      width: 100%;
      padding: 0 14px;
    }
    .intro-txt {
      p {
        padding-top: 12px;
      }
    }
  }
  .values {
    padding: 20px 0 0px;
    h5 {
      margin-bottom: 20px;
    }
    .valuesList {
      flex-wrap: wrap;
      .valusBox {
        margin-bottom: 14px;
        height: 300px;
        background-size: 100% auto;
        width: 100%;
        div {
          padding-top: 106px;
        }
        p {
          font-size: 26px;
          line-height: 30px;
        }
        span {
          font-size: 12px;
        }
      }
      div.valusBox:nth-child(2) {
        background-size: 100% auto;
      }
      div.valusBox:nth-child(3) {
        background-size: 100% auto;
      }
      div.valusBox:nth-child(4) {
        background-size: 100% auto;
      }
      div.valusBox:nth-child(5) {
        background-size: 100% auto;
      }
    }
  }
  .title {
    font-size: 17px;
    line-height: 20px;
  }
  h5 {
    font-size: 12px;
  }
  .map {
    padding: 30px 0 0;
    img {
      margin-top: -140px;
    }
  }
  .honor {
    padding-top: 40px;
    .title {
      margin-bottom: 30px;
    }
  }

  .tabBox {
    width: 100%;
    height: 265px;
    margin: auto;
    padding-bottom: 56px;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
  }
  .tabs {
    display: flex;
    p {
      margin: 0;
      flex: 1;
      text-align: center;
      font-size: 14px;
      line-height: 35px;
      background: #fafafa;
      box-sizing: border-box;
      cursor: pointer;
    }
    p.active {
      background: #fff;
      border-top: 1px solid #4a8fb8;
      color: #4a8fb8;
    }
  }
  .tabImg {
    display: flex;
    dl {
      margin: 30px 0 0;
      flex: 1;
      text-align: center;
      dt {
        img {
          width: 80%;
        }
      }
      dd {
        margin: -60px 0 0;
        font-size: 12px;
        color: #333;
        line-height: 23px;
        p {
          margin: 0;
          font-size: 12px;
          color: #333;
          line-height: 23px;
        }
      }
    }
  }
  .tabImg.three {
    padding-top: 5px;
    dt {
      img {
        width: 100%;
      }
    }
    dd {
      margin: -90px 0 0;
    }
  }
}
</style>