<template>
  <div id="app">
    <Header v-if="$route.name != 'BigScreen'" :windowWidth="windowWidth" />
    <router-view></router-view>
    <Footer v-if="$route.name != 'reservation' && $route.name != 'BigScreen'" :windowWidth="windowWidth" />
    <Up v-if="$route.name != 'BigScreen'" />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from './components/Footer.vue'
import Up from './components/up.vue'
export default {
  name: "App",
  components: {
    Header,
    Footer,
    Up
  },
  data() {
    return {
      windowWidth: 0,
    };
  },
  created() {
    console.log(this.$route.name)
    this.windowWidth = window.document.documentElement.clientWidth;
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
};
</script>

<style>
body,
html {
  margin: 0;
}
.el-menu-item:hover{
  background: none !important;
}
.tabImg.four .el-carousel,.el-carousel__container{
      height: 565px !important;
}
</style>
