<template>
  <div :class="windowWidth >= 1180 ? 'up' : 'up mobile'">
    <!-- <div
      class="service"
      :style="service ? 'background:#0065FF' : ''"
      @mouseenter="service = true;"
      @mouseleave="serviceTk ? service = false : service = true"
    >
      <img v-if="!service" src="../assets/common/icon-service.png" alt="" />
      <img v-if="service" src="../assets/common/icon-service-sel.png" alt="" />
    </div> -->
    <input id="isApp" value="1122" type="text" />
    <div class="serviceTk" @mouseenter="serviceTk = true;service = true" v-if="service" @mouseleave="service = false">
      <p class="title">联系我们</p>
      <dl>
        <dt>
          <img src="../assets/common/icon-call.png" alt="" class="img1" />
          <img src="../assets/common/icon-call-sel.png" class="img2" alt="" />
        </dt>
        <dd>
          <h6>咨询电话</h6>
          <span class="phone"
            >{{phone}} <img @click="copyNumber()" src="../assets/common/icon-copy.png" alt=""
          /></span>
        </dd>
      </dl>
      <dl>
        <dt>
          <img src="../assets/common/icon-msg.png" alt="" class="img1" />
          <img class="img2" src="../assets/common/icon-msg-sel.png" alt="" />
        </dt>
        <dd>
          <h6>业务咨询</h6>
          <span>问题留言，我们会尽快联系您</span>
        </dd>
      </dl>
    </div>
    <!-- <div class="reservation"  @click="toPage('reservation')" @mouseenter="service = false;serviceTk = false"> -->
    <div class="reservation"  @mouseenter="service = true;serviceTk = true">
      <img src="../assets/common/icon-play.png" class="img1" alt="" />
      <img src="../assets/common/icon-play-sel.png" class="img2" alt="" />
      <span>预约</span>
      <span>咨询</span>
    </div>
    <el-backtop :visibility-height="0" :bottom="100" @mouseenter="service = false;serviceTk = false">
      <div
        style="
          height: 100%;
          width: 100%;
          line-height: 50px;
          border-radius: 50px;
          box-shadow: 0px 7px 15px 0px rgba(100, 100, 111, 0.2);
        "
      >
        <img src="../assets/common/up.png" alt="" />
      </div>
    </el-backtop>
  </div>
</template>
<script>
export default {
  data() {
    return {
      service: false,
      serviceTk: false,
      phone:"18516854707",
      windowWidth: "",
    };
  },
  created(){
    this.windowWidth = window.document.documentElement.clientWidth;

  },
  methods:{
    toPage(name, num) {
      this.activeIndex = num;
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
    copyNumber(){
      var copyText = document.getElementById("isApp"); /* 选择复制内容 */
      copyText.select();
      copyText.setSelectionRange(
        0,
        99999
      );

      navigator.clipboard.writeText(this.phone);
      this.$message.success("复制成功");
    }
  }
};
</script>
<style lang="less" scoped>
.up.mobile{
  display: none;
}
.service {
  position: fixed;
  right: 24px;
  bottom: 271px;
  width: 50px;
  background: #fff;
  border-radius: 50px;
  height: 50px;
  box-shadow: 0px 7px 15px 0px rgba(100, 100, 111, 0.2);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  img {
    width: 32px;
    height: 32px;
  }
}
.reservation {
  position: fixed;
  box-shadow: 0px 7px 15px 0px rgba(100, 100, 111, 0.2);
  bottom: 162px;
  background: #fff;
  right: 24px;
  border-radius: 40px;
  cursor: pointer;
  width: 50px;
  padding: 10px 0;
  text-align: center;
  img.img1 {
    display: block;
    width: 32px;
    margin: 0 auto 4px;
  }
  .img2{
    display: none;width: 32px;
    margin: 0 auto 4px;
  }
  span {
    display: block;
    color: #666;
    font-size: 14px;
    line-height: 20px;
  }
}
.reservation:hover{
   background: #0065ff;
  .img1{
    display: none;
  }
  .img2{
    display: block;
  }
  span{
    color: #fff;
  }
}
.serviceTk {
  position: fixed;
  right: 78px;
  bottom: 95px;
  // bottom: 175px;
  width: 328px;
  height: 227px;
  background: url(../assets/common/bg.png) no-repeat;
  background-size: 100% 100%;
  .title {
    font-size: 12px;
    line-height: 17px;
    color: #6a7171;
    padding-top: 16px;
    width: 252px;
    margin: 16px auto 16px;
  }
  dl {
    width: 252px;
    height: 48px;
    display: flex;
    border-radius: 6px;
    padding: 7px 7px 5px;
    margin: 0 auto 16px;
    box-sizing: border-box;
    dt {
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      margin-right: 10px;
      position: relative;
      img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
      }
      .img1 {
        display: block;
      }
      .img2 {
        display: none;
      }
    }
    dd {
      flex: 1;
      margin: 0;
      h6 {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        color: #666;
        font-weight: 300;
      }
      span {
        font-size: 12px;
        line-height: 17px;
        color: #666;
        display: flex;
        align-items: center;
        img {
          width: 12px;
          height: 12px;
          margin-left: 6px;
          cursor: pointer;
        }
      }
      span.phone {
        color: #0065ff;
      }
    }
  }
  dl:hover {
    background: rgba(0, 101, 255, 0.2);
    .img1 {
      display: none;
    }
    .img2 {
      display: block;
    }
    dd {
      h6,
      span {
        color: #0065ff;
      }
    }
  }
}

#isApp {
  position: fixed;
  top: -9999px;
}
</style>
<style lang="less">
.el-backtop {
  width: 50px;
  height: 50px;
  right: 24px !important;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>