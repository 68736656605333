<template>
  <div class="header">
    <div class="big" v-if="windowWidth >= 900">
      <div class="left">
        <img
          class="logo"
          @click="toPage('home',1)"
          @mouseenter="
            menu2 = false;
            menu3 = false;
          "
          src="../assets/common/logo.png"
          alt=""
          srcset=""
        />
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item
            index="1"
            @click="toPage('home', 1)"
            @mouseenter="
              menu2 = false;
              menu3 = false;
            "
            >首页</el-menu-item
          >
          <el-menu-item
            class="itemhover"
            @mouseenter="
              menu2 = true;
              menu3 = false;
            "
            index="2" :style="menu2 ? 'color:#4A8FB8' : ''"
            >解决方案 <img style="margin-left:5px;width:14px" v-if="!menu2 && activeIndex != 2" src="../assets/common/arrowDown.jpg" alt=""> <img style="margin-left:5px;width:14px" v-if="menu2 || activeIndex == 2" src="../assets/common/arrowDown-blue.jpg" alt=""></el-menu-item>
          <el-menu-item
            class="itemhover"
            @mouseenter="
              menu3 = true;
              menu2 = false;
            "
            index="3" :style="menu3 ? 'color:#4A8FB8' : ''"
            >产品生态 <img style="margin-left:5px;width:14px" v-if="!menu3 &&  activeIndex != 3" src="../assets/common/arrowDown.jpg" alt=""> <img style="margin-left:5px;width:14px" v-if="menu3 || activeIndex == 3" src="../assets/common/arrowDown-blue.jpg" alt=""></el-menu-item>
          <el-menu-item
            index="4"
            @click="toPage('cooperate', 4)"
            @mouseenter="
              menu2 = false;
              menu3 = false;
            "
            >合作生态</el-menu-item
          >
          <el-menu-item
            index="5"
            @click="toPage('aboutUs', 5)"
            @mouseenter="
              menu2 = false;
              menu3 = false;
            "
            >关于我们</el-menu-item
          >
          <el-menu-item
            index="7"
            @click="toPage('download', 7)"
            @mouseenter="
              menu2 = false;
              menu3 = false;
            "
            >下载中心</el-menu-item
          >
        </el-menu>
      </div>
      <div
        class="right"
        @mouseenter="
          menu2 = false;
          menu3 = false;
        "
      >
        <p class="reservationBtn" @click="toPage('reservation',6)">预约演示</p>
        <span>18516854707</span>
      </div>
    </div>
    <div class="small" v-else>
      <div class="left">
        <img class="logo" src="../assets/common/logo.png" alt="" srcset="" />
      </div>
      <el-icon size="20" color="#000" @click="menuShow = !menuShow"
        ><Operation
      /></el-icon>
    </div>
    <el-collapse-transition class="menucollapseBig">
      <div
        @mouseleave="
          menu2 = false;
          menu3 = false;
        "
        :class="menu3 ? 'menu3' : 'menu3 menu2'"
        v-show="menu3 || menu2"
      >
        <div class="left">
          <div class="product" v-if="menu3">
            <h5>产品生态</h5>
            <p>
              <img
                src="../assets/common/icon-right.png"
                alt=""
              />方便快捷的产品应用体系
            </p>
            <p>
              <img
                src="../assets/common/icon-right.png"
                alt=""
              />满足不同用户差异化需求
            </p>
            <p>
              <img
                src="../assets/common/icon-right.png"
                alt=""
              />安全高效解决心理服务难题
            </p>
          </div>
          <div class="product" v-else>
            <h5>解决方案</h5>
            <p>
              <img
                src="../assets/common/icon-right.png"
                alt=""
              />契合场景，寻找适合您的企业的应用场景
            </p>
            <p>
              <img
                src="../assets/common/icon-right.png"
                alt=""
              />深耕行业，推荐最适合的行业解决方案
            </p>
            <p>
              <img
                src="../assets/common/icon-right.png"
                alt=""
              />携手伙伴，重构数字化心理服务体验
            </p>
          </div>
        </div>
        <div class="right">
          <div class="lists" v-if="menu3">
            <p class="type">
              <img src="../assets/common/icon-pc.png" alt="" /> 企业端
            </p>
            <h3>寻找适合您企业的产品</h3>
            <div class="card" @click="toPage('productMonitor', 3)">
              <p>心理情绪监测可穿戴设备</p>
              <span>通过设备穿戴可实时、准确地检测和分析佩戴者状态</span>
            </div>
            <div class="card" @click="toPage('productGroup', 3)">
              <p>情绪压力筛查及危机预警系统</p>
              <span>为大小团体提供有效的心理健康状态筛查及预警服务</span>
            </div>
            <div class="card" @click="toPage('productScreen', 3)">
              <p>幸福心联平台</p>
              <span>助力各心理服务站解决人们普及心理健康教育</span>
            </div>
          </div>
          <div class="lists" v-if="menu3">
            <p class="type">
              <img src="../assets/common/icon-mobile.png" alt="" /> 用户端
            </p>
            <h3>寻找适合您的产品</h3>
            <div class="card" @click="toPage('productSEEMO', 3)">
              <p>Seemo（犀陌）APP</p>
              <span>一款为身心情绪管理而设计的APP</span>
            </div>
            <div class="card" @click="toPage('productXFXL', 3)">
              <p>心理服务咨询平台</p>
              <span>为广大用户提供全方位的心理健康科普、服务与咨询等</span>
            </div>
            <div class="card" @click="toPage('productQxb', 3)">
              <p>情绪宝APP</p>
              <span>专为特殊场景打造的专业的心理健康数字化产品</span>
            </div>
          </div>
          <div class="lists" v-if="menu2">
            <p class="type">业务场景解决方案</p>
            <h3>寻找适合您的企业应用场景</h3>
            <!-- <div class="card">
              <p>政府解决方案</p>
              <span>助力政府多场景解决心理普测问题</span>
            </div> -->
            <div class="card" @click="toPage('solution/enterprise', 2)">
              <p>政企解决方案</p>
              <span>助力政府企业多场景解决心理普测问题</span>
            </div>
            <div class="card" @click="toPage('solution/education', 2)">
              <p>教育解决方案</p>
              <span>助力各个中小学多场景解决心理普测问题</span>
            </div>
          </div>
          <div class="lists" v-if="menu2">
             <p class="type">行业解决方案</p>
            <h3>寻找适合您的企业所在行业的应用场景</h3>
           <div class="card" @click="toPage('solution/medicalTreatment', 2)">
              <p>医疗解决方案</p>
              <span>助力医疗行业多场景解决心理普测问题</span>
            </div>
            <!-- <div class="card">
              <p>培训解决方案</p>
              <span>助力培训行业多场景解决心理普测问题</span>
            </div>
            <div class="card">
              <p>生活解决方案</p>
              <span>助力生活行业多场景解决心理普测问题</span>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="menu2 menu3" v-if="menu2" v-show="menu2">
        <div class="left">
          
        </div>
        <div class="right">
          
        </div>
      </div> -->
    </el-collapse-transition>

    <el-collapse-transition class="menucollapse">
      <div v-show="menuShow">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          @select="handleSelect"
        >
          <el-menu-item index="1">首页</el-menu-item>
          <el-sub-menu index="2">
            <template #title>解决方案</template>
            <el-menu-item index="2-1">item one</el-menu-item>
            <el-menu-item index="2-2">item two</el-menu-item>
            <el-menu-item index="2-3">item three</el-menu-item>
          </el-sub-menu>
          <el-menu-item index="3">产品案例</el-menu-item>
          <el-menu-item index="4" @click="toPage('cooperate')"
            >合作生态</el-menu-item
          >
          <el-menu-item index="5" @click="toPage('aboutUs')"
            >关于我们</el-menu-item
          >
          <el-menu-item index="7" @click="toPage('download')"
            >下载中心</el-menu-item
          >
        </el-menu>

        <div class="right">
          <p class="reservationBtn" @click="toPage('reservation',6)">预约演示</p>
          <span>18516854707</span>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  name: "header",
  props: ["windowWidth"],
  data() {
    return {
      menu3: false,
      menu2: false,
      activeIndex: "1",
      menuShow: false,
    };
  },
  created() {
    // console.log(this.windowWidth);
  },
  methods: {
    toPage(name, num) {
      this.activeIndex = num;
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false
      }
      this.menu3 = false;
      this.menu2 = false;
      this.$router.push({
        path: `/${name}`,
      });
    },
    handleSelect(key, keyPath) {
      // 跳转路由用
      this.menuShow = false;
    },
    handleClose() {},
    handleOpen() {},
    setPageIndex() {
      switch (this.$route.name) {
        case "cooperate":
          this.activeIndex = 4;
          break;
         case "download":
          this.activeIndex = 7;
          break;
        case "Home":
          this.activeIndex = 1;
          break;
        case "aboutUs":
          this.activeIndex = 5;
          break;
        case "reservation":
          this.activeIndex = 6;
          break;
        case "education":
          this.activeIndex = 2;
          break;
        case "enterprise":
          this.activeIndex = 2;
          break;
        case "medicalTreatment":
          this.activeIndex = 2;
          break;
        case undefined:
          if(this.$route.path == '/'){
            this.activeIndex = 1;
          }else{
            this.activeIndex = 2;
          }
          
          break;
        default:
          this.activeIndex = 3;
      }
    },
  },
  watch: {
    "$route.path": "setPageIndex",
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.header {
  background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 100%);
  // z-index: 999;
  // position: fixed;
  // top: 0;
  position: relative;
  z-index: 33;
  // left: 0;
  width: 100%;
  .big {
    max-width: 1200px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left,
    .right {
      display: flex;
      align-items: center;
    }
    .left {
      flex: 1;
    }
    img.logo {
      flex-shrink: 0;
      margin-right: 48px;
      height: 40px;
      cursor: pointer;
    }
    .el-menu-demo {
      border: none;
      display: flex;
      flex: 1;
      .el-sub-menu.is-active,
      .el-menu-item.is-active {
        border: none;
      }
      .el-sub-menu.is-active,
      .el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
        border: none !important;
      }
      p {
        font-size: 14px;
        color: #333333;
        margin: 0 36px 0 0;
        cursor: pointer;
      }
      p.active {
        color: #4a8fb8;
      }
    }
    .right {
      p {
        height: 30px;
        width: 84px;
        font-size: 14px;
        border-radius: 6px;
        border: 1px solid #4a8fb8;
        box-sizing: border-box;
        margin-right: 8px;
        color: #4a8fb8;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
      }
      span {
        color: #4a8fb8;
        font-size: 16px;
      }
    }
  }

  .small {
    height: 88px;
    padding: 0 10px;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img.logo {
      height: 40px;
      float: left;
    }
  }
}
.menucollapseBig{
  position: absolute;
  height: 600px;
  top: 58px;
  z-index: 9999;
  width: 100%;
}
.menucollapse {
  position: fixed;
  top: 58px;
  width: 100%;
  .right {
    display: flex;
    align-items: center;
    padding-left: 20px;
    p {
      height: 30px;
      width: 84px;
      font-size: 14px;
      border-radius: 6px;
      border: 1px solid #4a8fb8;
      box-sizing: border-box;
      margin-right: 8px;
      color: #4a8fb8;
      text-align: center;
      line-height: 28px;
    }
    span {
      color: #4a8fb8;
      font-size: 16px;
    }
  }
}
.itemhover {
  i {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }
}
.itemhover:hover {
  i {
    transform: rotate(180deg);
  }
}
.menu3 {
  display: flex;
  height: 600px;
  box-sizing: border-box;
  .left {
    width: 29.6%;
    flex-shrink: 0;
    background: #f4fbff;
    text-align: right;
    background: url(../assets/common/menu-left.png) no-repeat #f4fbff;
    background-position-x: right;
    background-size: auto 600px;
    .product {
      width: 300px;
      float: right;
      box-sizing: border-box;
      padding-top: 38px;
      h5 {
        font-size: 20px;
        line-height: 29px;
        margin: 0 0 16px;
        text-align: left;
        color: #222;
      }
      p {
        margin: 0 0 18px;
        display: flex;
        font-size: 14px;
        align-items: center;
        line-height: 20px;
        color: #222;
        img {
          width: 15px;
          height: 15px;
          margin-right: 9px;
        }
      }
    }
  }
  .right {
    flex: 1;
    background: #fff;
    display: flex;
    padding-left: 36px;
    padding-top: 35px;
    box-sizing: border-box;
    .lists {
      margin-right: 30px;
      margin-top: 15px;
      width: 442px;
      .type {
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 23px;
        color: #222;
        img {
          margin-right: 15px;
          height: 28px;
        }
      }
      h3 {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin: 5px 0 15px;
        padding-bottom: 16px;
        border-bottom: 1px dashed #d8d8d8;
      }
    }
    .card {
      background: #ffffff;
      box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.06);
      border-radius: 4px 4px 4px 4px;
      margin-bottom: 12px;
      padding: 14px 12px;
      cursor: pointer;
      p {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        color: #333;
      }
      span {
        margin-top: 6px;
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #999;
      }
    }
  }
}
.menu2 {
  .product {
    width: 346px;
    float: right;
  }
}
</style>
