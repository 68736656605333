<template>
  <div class="seemo">
    <div class="banner">
      <div class="bannerContainer">
        <div class="left">
          <div class="txt">Seemo（犀陌）APP</div>
          <p>
            <span
              >面对产业智能化机遇与挑战，百度智能云与合作伙伴共建产业智能化新生态，携手打造数字化转型与智能化升级的一站式产品、方案和服务，让产业智能化更简单。</span
            >
          </p>
          <div class="banner-btn"><em @click="downApp()">立即下载</em></div>
        </div>
        <div class="right">
          <img class="logo" src="../../assets/product/seemo/logo.png" alt="" />
          <img src="../../assets/product/seemo/home-right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="seemoContainer">
      <div class="top">
        <h3>我们的优势</h3>
        <div class="intro">科技、普惠、保障型心理健康服务体系</div>
        <div class="lists">
          <dl>
            <dt><img src="../../assets/product/seemo/icon1.png" alt="" /></dt>
            <dd>
              <p>实时/监测监测</p>
              <span
                >通过穿戴设备，实时捕捉您或家人的情绪变化，让您随时掌握自己的心理状态</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/seemo/icon2.png" alt="" /></dt>
            <dd>
              <p>医疗级报告</p>
              <span
                >根据监测结果，生成医疗级情绪监测报告，为您提供科学、准确、专业的心理评估，与情绪建议指导</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/seemo/icon3.png" alt="" /></dt>
            <dd>
              <p>在线心理服务</p>
              <span
                >拥有8000+心理专家人才，为您提供多种形式的在线心理服务，满足您不同的心理需求</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/seemo/icon4.png" alt="" /></dt>
            <dd>
              <p>社交互动</p>
              <span
                >在话题广场，与其他用户分享自己的心得感受，找到共鸣和支持，建立属于自己的用户圈子</span
              >
            </dd>
          </dl>
        </div>
      </div>
      <div class="bottom" ref="downapp">
        <h3>产品功能与特点</h3>
        <div class="app">
          <div class="left">
            <img src="../../assets/product/seemo/iphone13.png" alt="" />
            <div class="circle circle1">实时心率</div>
            <div class="circle circle2">血氧血压</div>
            <div class="circle circle3">实时测评</div>
            <div class="circle circle4">报告解读</div>
            <div class="circle circle5">一键呼叫</div>
          </div>
          <div class="right">
            <div class="bigTxt">看得见，更懂你</div>
            <div class="smallTxt">
              when you start to see the mood,you will see more.
            </div>
            <div class="smallTxt">See mood,and you'll see more.</div>
            <div class="down">
              <div class="downBtn">
                <div class="android">
                  <img
                    src="../../assets/product/seemo/android.png"
                    alt=""
                  />Android扫码下载
                </div>
                <div class="ios">
                  <img
                    src="../../assets/product/seemo/ios.png"
                    alt=""
                  />iOS扫码下载
                </div>
              </div>
              <div class="ewm">
                <img src="../../assets/product/seemo/ewm.jpg" alt="" />
              </div>
            </div>
            <div class="tabs">
              <dl>
                <dt><img src="../../assets/product/seemo/icon-1.png" alt=""></dt>
                <dd>心理咨询1V1</dd>
              </dl>
              <dl>
                <dt><img src="../../assets/product/seemo/icon-2.png" alt=""></dt>
                <dd>名师心理指导</dd>
              </dl>
            </div>
            <div class="tabs">
              <dl>
                <dt><img src="../../assets/product/seemo/icon-3.png" alt=""></dt>
                <dd>专业报告解读</dd>
              </dl>
              <dl>
                <dt><img src="../../assets/product/seemo/icon-4.png" alt=""></dt>
                <dd>话题广场，随时分享</dd>
              </dl>
            </div>
            <div class="tabs">
              <dl>
                <dt><img src="../../assets/product/seemo/icon-5.png" alt=""></dt>
                <dd>您的情绪，实时测评</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="introList">
          <dl>
            <dt><img src="../../assets/product/seemo/home.png" alt=""></dt>
            <dd>
              <p>情绪心率一目了然，</p>
              <p>健康跟进快捷高效。</p>
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/seemo/my.png" alt=""></dt>
            <dd style="padding-right: 31px">
              <p>设备和亲属绑定，</p>
              <p>切换设备更快捷。</p>
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/seemo/find.png" alt=""></dt>
            <dd style="padding-right:30px">
              <p>深入用户洞察，</p>
              <p>深度分享生活。</p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    downApp(){
      this.$refs.downapp.scrollIntoView({
          behavior: "smooth",
        });
    }
  }
};
</script>
<style lang="less" scoped>
.banner {
  background: url(../../assets/about/banner.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  height: 500px;
  position: relative;
  margin-top: -58px;
  overflow: hidden;
  .bannerContainer {
    width: 1180px;
    justify-content: space-between;
    display: flex;
    margin: auto;
    .left {
      width: 521px;
      flex-shrink: 0;
      .txt {
        font-size: 44px;
        line-height: 64px;
        color: #222;
        position: relative;
        margin: auto;
        padding-top: 138px;
      }

      p {
        font-size: 16px;
        line-height: 23px;
        margin: 24px auto 0;

        span {
          display: block;
          width: 521px;
          color: #222;
        }
      }

      .banner-btn {
        margin: 0 auto 0;
        position: absolute;
        bottom: 87px;
        em {
          display: block;
          font-style: normal;
          cursor: pointer;
          background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
          width: 190px;
          height: 52px;
          border-radius: 30px;
          text-align: center;
          line-height: 52px;
          color: #0065ff;
          font-size: 16px;
        }
      }
    }
    .right {
      padding-top: 121px;
      display: flex;
      justify-content: flex-end;
      img {
        width: 267px;
      }
      .logo {
        width: 144px;
        height: 144px;
        margin-top: 112px;
        margin-right: 36px;
      }
    }
  }
}
.seemoContainer {
  background: url(../../assets/product/bg.png) no-repeat;
  background-size: 100% auto;
  .top {
    padding: 84px 0 80px;
    width: 1180px;
    margin: 0 auto;
    .intro {
      color: #798a94;
      line-height: 29px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 80px;
    }
    .lists {
      display: flex;
      dl {
        flex: 1;
        margin: 0 95px 0 0;
        text-align: center;
        dt {
          width: 50px;
          height: 50px;
          margin: 0 auto;
          img {
            width: 100%;
            float: left;
            height: 100%;
          }
        }
        dd {
          margin: 0;
          p {
            margin: 14px 0 9px;
            text-align: center;
            font-size: 20px;
            line-height: 29px;
            color: #3d3d3d;
          }
          span {
            display: block;
            line-height: 20px;
            text-align: left;
            color: #3d3d3d;
            font-size: 14px;
          }
        }
      }
      dl:last-child {
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 34px;
    color: #011739;
    line-height: 49px;
    margin: 0 0 12px;
    text-align: center;
  }
  .bottom {
    text-align: center;
    padding: 84px 0 80px;
    background: rgba(0, 101, 255, 0.06);
    .app {
      margin-top: 68px;
      display: flex;
      margin-bottom: 194px;
      position: relative;
      height: 701px;
      .left {
        padding-top: 19px;
        display: flex;
        .circle {
          position: absolute;
          width: 90px;
          height: 90px;
          background: #0065ff;
          color: #fff;
          text-align: center;
          line-height: 90px;
          font-size: 16px;
          box-shadow: 0px 0px 16px 0px rgba(69, 159, 186, 0.6);
          border-radius: 100px;
        }
        .circle1 {
          left: 15%;
          top: 138px;
        }
        .circle2 {
          left: 12%;
          top: 295px;
        }
        .circle3 {
          left: 15%;
          top: 491px;
        }
        .circle4 {
          left: 39%;
          top: 204px;
          width: 94px;
          height: 94px;
          line-height: 94px;
        }
        .circle5 {
          width: 138px;
          height: 138px;
          left: 35.6%;
          line-height: 138px;
          top: 415px;
        }
        img {
          height: 701px;
          width: auto;
          position: absolute;
          left: 20%;
        }
      }
      .right {
        position: relative;
        left: 48%;
        text-align: left;
        .bigTxt {
          font-size: 80px;
          line-height: 88px;
          margin-bottom: 8px;
          color: #0065ff;
          font-weight: 400;
        }
        .smallTxt {
          line-height: 48px;
          font-size: 40px;
          color: #0065ff;
          font-style: italic;
          margin-top: 10px;
        }
        .down {
          display: flex;
          align-items: center;
          margin-bottom: 49px;
          .downBtn {
            flex-shrink: 0;
            div {
              display: flex;
              align-items: center;
              width: 365px;
              font-size: 20px;
              height: 65px;
              line-height: 65px;
              box-sizing: border-box;
              border-radius: 70px;
              margin-bottom: 5px;
              margin-top: 35px;
              justify-content: center;
              background: #0065ff;
              color: #fff;
              img {
                width: 34px;
                height: 34px;
                margin-right: 8px;
              }
            }
            div.ios{
              img{
                margin-top: -5px;
              }
            }
          }
          .ewm {
            width: 158px;
            height: 158px;
            margin-top: 35px;
            margin-left: 27px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .tabs{
          margin-bottom: 25px;
          display: flex;
          dl{
            flex-shrink: 0;
            width: 235px;
            margin: 0;
            display: flex;
            dt{
              width: 54px;
              height: 54px;
              background:rgba(255,255,255,0.5);
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                width: 40px;
                height: 40px;
              }
            }
            dd{
              margin-left: 15px;
              font-size: 18px;
              color: #062144;
              line-height: 54px;
            }
          }
        }
      }
    }
    .introList{
      display: flex;
      align-items: center;
      justify-content: center;
      dl{
        margin: 0 101px 0 0;
        dt{
          text-align: center;
          img{
            height: 506px;
          }
        }
        dd{
          margin: 40px 0 0;
          padding-right: 20px;
          text-align: right;
          p{
            margin: 0;
            font-size: 20px;
            line-height: 36px;
            color: #798a94;
          }
        }
      }
      dl:last-child{
        margin: 0;
      }
    }
  }
}
</style>