<template>
  <div class="monitor">
    <div class="banner">
      <div class="bannerContainer">
        <div class="left">
          <div class="txt">心理情绪监测可穿戴设备</div>
          <p>
            <span
              >基于生理信号和人工智能技术的心理情绪监测可穿戴设备，能够实时、准确地检测和分析用户的情绪状态，如抑郁、应激、焦虑、高压等</span
            >
          </p>
          <div class="banner-btn" @click="toPage('reservation')"><em>立即预约演示</em></div>
        </div>
        <div class="right">
          <img src="../../assets/product/monitor/banner-right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="monitorContainer">
      <div class="top">
        <h3>心理情绪监测可穿戴设备产品优势</h3>
        <div class="intro">科技、普惠、保障型心理健康服务体系</div>
        <div class="lists">
          <dl>
            <dt><img src="../../assets/product/monitor/icon1.png" alt="" /></dt>
            <dd>
              <p>简单快捷</p>
              <span
                >能够实时采集和分析用户的生理信号，及时反馈用户的情绪状态，避免延误或遗漏</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/monitor/icon2.png" alt="" /></dt>
            <dd>
              <p>拒绝答题</p>
              <span
                >采用了轻便、舒适、易于佩戴的可穿戴设备，不会给用户带来额外的负担或不适感</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/monitor/icon3.png" alt="" /></dt>
            <dd>
              <p>智能科学</p>
              <span
                >采用了先进的人工智能技术，结合了多种生理信号和情绪模型，提高了情绪识别的准确率和可靠性</span
              >
            </dd>
          </dl>
          <!-- <dl>
            <dt><img src="../../assets/product/monitor/icon4.png" alt="" /></dt>
            <dd>
              <p>应用性</p>
              <span
                >适用于各种情绪监测与管理，可以为不同场合和目的提供服务，如社区、教育、政企、医疗等</span
              >
            </dd>
          </dl> -->
        </div>
      </div>
      <div class="bottom">
        <!-- <h3>产品功能与特点</h3>
        <img src="../../assets/product/monitor/container.png" alt=""> -->
        <h3>心理情绪监测可穿戴设备原理</h3>
        <div class="introList">
          <dl>
            <dt><img src="../../assets/product/monitor/intro-1.png" alt="" srcset=""></dt>
            <dd>心率变异性（Heart Rate Variability 简称HRV）能够客观和动态地反映人的情绪变化、疲劳程度以及应激状态下的心理变化，是反映生理弹性和行为弹性的重要指标。 通过对心率变异性的分析可以探索生理、心理、情绪和行为过程的动态互动关系。</dd>
          </dl>
          <dl>
            <dt><p>不同情绪状态下的心率变异对比图右图：</p> 
              尽管两个图形反映的心率都是在60-90次/分钟的正常范围，但是在两个心率变异图型里还是可以看到，一个人的心率变异性(心率变异)在沮丧（frustration）的时候（上图）是不稳定的，呈浅波幅型，曲线没有随着每一次呼吸而出现上下摆动（即心率不随呼、吸而变化）。快乐（appreciation）的心情时（下图），心率变异曲线表现出更有规律的上下摆动型心率变异特征，与呼气、吸气动作是吻合的。通过大量的数据模型，可对放松、饱满、焦虑、抑郁等情绪进行区分。
            </dt>
            <dd><img src="../../assets/product/monitor/intro-2.png" alt="" srcset=""></dd>
          </dl>
          <dl>
            <dt>
              <img src="../../assets/product/monitor/intro-3.png" alt="" srcset=""> 
              <div>通俗理解：心电图波峰，一分钟内多少个，心率就是多少，假如两个人都是73个，心率就都是73。但是两个波峰的间期，一个人忽大忽小 一个人很平均，两个人心理状态完全不一样。</div>
            </dt>
            <dd>情绪手环通过采集的心率间期变化， 分析HRV（Heart Rate Variability 心率变异性）中SDNN、rMSSD、等指标，综合压力指数、自主神经平衡性等形成情绪心境特征模型，判断包括焦虑、高压、抑郁、疲劳、放松、愉悦、饱满等情绪状态。情绪心境特征在一定时间范围内具有稳定性，未经特殊事件冲击，不易发生变化，因此可应用于心理状态评估和监测预警。 • 正面情绪如感激等能促进自主神经系统的有序平衡，从而提升激素和免疫系统的平衡性以及大脑的效率。</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    downApp(){
      this.$refs.downapp.scrollIntoView({
          behavior: "smooth",
        });
    },

    toPage(name){
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
  }
};
</script>
<style lang="less" scoped>
.banner {
  background: url(../../assets/about/banner.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  height: 500px;
  position: relative;
  margin-top: -58px;
  overflow: hidden;
  .bannerContainer {
    width: 1180px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    .left {
      width: 521px;
      flex-shrink: 0;
      .txt {
        font-size: 44px;
        line-height: 64px;
        color: #222;
        position: relative;
        margin: auto;
        font-weight: 400;
        padding-top: 138px;
      }

      p {
        font-size: 16px;
        line-height: 23px;
        margin: 24px auto 0;

        span {
          display: block;
          width: 521px;
          color: #222;
        }
      }

      .banner-btn {
        position: absolute;
        bottom: 87px;
        em {
          display: block;
          font-style: normal;
          cursor: pointer;
          background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
          width: 190px;
          height: 52px;
          border-radius: 30px;
          text-align: center;
          line-height: 52px;
          color: #0065ff;
          font-size: 16px;
        }
      }
    }
    .right {
      padding-top: 84px;
      display: flex;
      justify-content: flex-end;
      img {
        width: 471px;
        height: 371px;
      }
    }
  }
}
.monitorContainer {
  background: url(../../assets/product/bg.png) no-repeat;
  background-size: 100% auto;
  background-position-y: top;
  .top {
    padding: 84px 0 80px;
    width: 1180px;
    margin: 0 auto;
    .intro {
      color: #798a94;
      line-height: 29px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 80px;
    }
    .lists {
      display: flex;
      dl {
        flex: 1;
        margin: 0 95px 0 0;
        text-align: center;
        dt {
          width: 50px;
          height: 50px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            float: left;
          }
        }
        dd {
          margin: 0;
          p {
            margin: 14px 0 9px;
            text-align: center;
            font-size: 20px;
            line-height: 29px;
            color: #3d3d3d;
          }
          span {
            display: block;
            line-height: 20px;
            text-align: left;
            color: #3d3d3d;
            font-size: 14px;
          }
        }
      }
      dl:last-child {
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 34px;
    color: #011739;
    line-height: 49px;
    margin: 0 0 12px;
    text-align: center;
  }
  .bottom {
    text-align: center;
    width: 1180px;
    margin: 0 auto;
    padding: 84px 0 80px;
    // background: rgba(0, 101, 255, 0.06);
    // img{
    //   margin-top: 68px;
    //   width: 43.7%;
    // }
    .introList{
      padding-top: 164px;
      dl{
        display: flex;
        font-size: 20px;
        line-height: 36px;
        align-items: center;
        color: #798A94;
        justify-content: space-between;
      }
      dl:first-child{
        dt{
          width: 491px;
          img{
            width: 631px;
          }
        }
        dd{
          width: 452px;
          font-size: 20px;
          text-align: left;
        }
      }
      dl:nth-child(2){
        margin: 209px 0 0;
        dt{
          width: 634px;
          text-align: left;
          p{
            margin: 9px 0;
            font-size: 34px;;
            line-height: 49px;
            color: #011739;
            font-weight: 500;
          }
        }
        dd{
          width: 491px;
          img{
            width: 472px;;
          }
        }
      }
      dl:last-child{
        margin: 209px 0 0;
        dt{
          // text-align: center;
          width: 634px;
          // box-sizing: border-box;
          img{
            width: 314px;
            text-align: center;
          }
          div{
            padding: 0 72px;
            text-align: left;
            font-size: 16px;;
            color: #999;
            line-height: 24px;;
          }
        }
        dd{
          width: 491px;
          text-align: left;
        }
      }
    }
  }
}
</style>