<template>
  <div class="medicalTreatment">
    <div class="banner">
      <div class="txt">医疗应用场景</div>
      <p>
        <span
          >为企业提供整体解决方案，帮助企业搭建心理驿站、专兼职心理服务队伍、心理服务体系，助力建设社会心理服务体系。</span
        >
      </p>
      <div class="banner-btn" @click="toPage('reservation')"><em>立即预约演示</em></div>
    </div>
    <div
      class="tabs"
      v-show="tabIndex != 4"
      :style="
        scrollNum > 417
          ? 'position:fixed;top:0px;width:100%;z-index:2'
          : 'position:relative'
      "
    >
      <div :style="scrollNum > 417 ? 'top:0;width:100%' : ''">
        <p
          @click="clickItemBtn('conRef1')"
          :class="tabIndex == 0 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 0 ? 'background:#0065FF;color:#fff' : ''"
        >
          方案优势
        </p>
        <p
          @click="clickItemBtn('conRef2')"
          :class="tabIndex == 1 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 1 ? 'background:#0065FF;color:#fff' : ''"
        >
          解决方案介绍
        </p>
        <p
          @click="clickItemBtn('conRef3')"
          :class="tabIndex == 2 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 2 ? 'background:#0065FF;color:#fff' : ''"
        >
          解决方案全景
        </p>
        <p
          @click="clickItemBtn('conRef4')"
          :class="tabIndex == 3 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 3 ? 'background:#0065FF;color:#fff' : ''"
        >
          客户案例
        </p>
      </div>
    </div>
    <div
      class="tabMenu1"
      :style="scrollNum > 417 ? 'padding-top:60px' : ''"
      ref="conRef1"
    >
     <div class="bigBox">
      <div class="title">方案优势</div>
      <div class="intro">让心理健康成为互联网心动力</div>
      <div class="slide">
        <p
          v-for="(item, index) in slideList"
          :key="index"
          @click="setCurrent(index, 'tab')"
          :class="slideIndex == index ? 'active' : ''"
        >
          {{ item.title }}
        </p>
      </div>
      <div class="slideIntro">
        <el-carousel
          ref="carousel"
          :autoplay="false"
          height="454px"
          @change="getCurrent"
          indicator-position="none"
          arrow="never"
        >
          <el-carousel-item v-for="item in slideList" :key="item.name">
            <img :src="item.img" alt="" srcset="" />
            <div class="content">
              <h3>{{ item.title }}</h3>
              <p v-html="item.intro"></p>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="slideBtn">
          <div class="last" @click="setCurrent(-1, 'btn')">&lt;</div>
          <div class="next" @click="setCurrent(1, 'btn')">&gt;</div>
        </div>
      </div>
     </div>
    </div>
    <div class="tabMenu2" ref="conRef2">
      <div class="title" v-animate="'move-up'">解决方案介绍</div>
      <div class="intro" v-animate="'move-up'">将医疗和心理情绪健康监测相结合</div>
      <div class="menu2Container">
        <div class="text">
          <p>将互联网医院、医疗机构、体检机构和心理情绪健康监测相结合，以综合的方式来解决患者身体和心理方面的问题。这种综合性的方法通常被称为“心身医学”或“心身融合”治疗，旨在更全面地关注患者的身体和心理健康，以提供更有效的治疗和康复。 为医院提供数字心理健康体检系统、服务管理系统、企业EAP、 心理健康管理等数字化一站式心理健康服务解决方案。构建医院-社区 （企业）-家庭心理健康服务联合体，推动医院医疗、心理延伸服务到社 区进家庭，为企业事业单位员工心理健康管理与服务提供便捷、安全、 高效的绿色平台，提高心理健康服务机构综合服务能力。
          </p>
        </div>
      </div>
    </div>
    <div class="tabMenu3" ref="conRef3">
      <div class="title" v-animate="'move-up'">解决方案全景</div>
      <div class="intro" v-animate="'move-up'">架构完善，实现更复杂的场景问题解决</div>
      <div class="menu3Container">
        <div class="top">
          <div class="left">
            <div>
              <img src="../../assets/solution/icon-menu.png" alt="" />
              <p>解决方案</p>
            </div>
          </div>
          <div class="right">
            <dl>
              <dt>政企解决方案</dt>
              <dd>司法丨军工丨交通</dd>
            </dl>
            <dl>
              <dt>教育解决方案</dt>
              <dd>K12院校丨幼儿园丨泛教育丨教育局</dd>
            </dl>
            <dl>
              <dt>医疗解决方案</dt>
              <dd>互联网医院丨医疗结构丨体检机构</dd>
            </dl>
          </div>
        </div>
        <div class="center">
          <div class="left">
            <div>
              <img src="../../assets/solution/icon-menu.png" alt="" />
              <p>应用平台</p>
            </div>
          </div>
          <div class="right">
            <dl>
              <dt>心理情绪监测系统</dt>
              <dd>
                <p>公有云/私有云</p>
                <p>混合云部署</p>
              </dd>
              <dd>
                <p>运维保障</p>
                <p>定制开发</p>
              </dd>
            </dl>

            <dl>
              <dt>数据可视化监控平台</dt>
              <dd>
                <p>多源异构数据融合</p>
              </dd>
              <dd>
                <p>大数据统计分析</p>
                <p>定制开发</p>
              </dd>
            </dl>
            <dl>
              <dt>心态预警与评估系统</dt>
              <dd>
                <p>风险等级预警</p>
              </dd>
              <dd>
                <p>智能短信</p>
                <p>智能外呼</p>
              </dd>
            </dl>

            <dl>
              <dt>心理服务站信息化</dt>
              <dd>
                <p>智能舒心仓</p>
                <p>可视化数据大屏</p>
              </dd>
              <dd>
                <p>放松椅</p>
                <p>智能化空间建设</p>
              </dd>
            </dl>

            <dl>
              <dt>幸福心联服务平台</dt>
              <dd>
                <p>心理需求发布</p>
                <p>心理人才在线培训</p>
              </dd>
              <dd>
                <p>在线心理服务</p>
                <p>心理人才考试系统</p>
              </dd>
            </dl>

            <dl>
              <dt>Seemo(犀陌)移动端</dt>
              <dd>
                <p>健康体征监测</p>
                <p>社区通讯</p>
              </dd>
              <dd>
                <p>在线心理咨询</p>
                <p>电商</p>
              </dd>
            </dl>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            <div>
              <img src="../../assets/solution/icon-menu.png" alt="" />
              <p>底层服务</p>
            </div>
          </div>
          <div class="right">
            <div class="rightTop">
              <p>云原生</p>
              <p>心理情绪AI算法</p>
              <p>大数据</p>
              <p>AIGC</p>
              <p>边缘计算</p>
              <p>物联网</p>
            </div>
            <div class="rightBottom">
              <p>计算服务</p>
              <p>储存服务</p>
              <p>网络服务</p>
              <p>基础服务</p>
              <p>硬件服务</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabMenu4" ref="conRef4">
      <div class="title" v-animate="'move-up'">客户案例</div>
      <div class="intro" v-animate="'move-up'">专属数据解决方案与服务，伴随客户持续成长</div>
      <div class="menu4Container">
        <dl>
          <dt><img src="../../assets/solution/medicalTreatment/news1.png" alt="" /></dt>
          <dd>
            <p class="name">武汉长江航运总医院</p>
            <p class="msg">
              武汉长江航运总医院是一家集医疗、教学、科研、预防、保健于一体的综合性三级甲等医院，其医疗水平和专业技术在国内外享有很高的声誉。该院不仅关注身体上的健康，也非常重视心理健康方面的服务。
            </p>
            <div class="type">
              <span>医疗</span>
            </div>
          </dd>
        </dl>
        <dl>
          <dt><img src="../../assets/solution/medicalTreatment/news2.png" alt="" /></dt>
          <dd>
            <p class="name">广西南宁第二妇幼保健院</p>
            <p class="msg">
              名流体检与心理健康之间存在密切的联系。一方面，通过名流体检可以及早发现潜在的身体健康问题，这有助于避免因疾病带来的心理压力和负担。另一方面，心理健康状况可以直接影响身体健康状况。如果名流们长期处于紧张、焦虑或抑郁等不良心理状态，可能会导致身体疾病的产生。因此，名流体检与心理健康是相辅相成的。
            </p>
            <div class="type">
              <span>医疗</span>
            </div>
          </dd>
        </dl>
        <dl>
          <dt><img src="../../assets/solution/medicalTreatment/news3.png" alt="" /></dt>
          <dd>
            <p class="name">成都市精神卫生中心</p>
            <p class="msg">
              成都市精神卫生中心是成都市重要的医疗卫生机构之一，主要致力于精神卫生和心理健康服务。该中心拥有先进的设备、专业的医疗团队和较高的技术水平，为成都市及周边地区的人民提供全方位、优质的精神卫生和心理健康服务。
            </p>
            <div class="type">
              <span>医疗</span>
            </div>
          </dd>
        </dl>
      </div>
      <div class="menu4Number">
        <dl>
          <dt>13417<span>+</span></dt>
          <dd>项目广泛应用</dd>
        </dl>
        <dl>
          <dt>237<span>+</span></dt>
          <dd>客户共同选择</dd>
        </dl>
        <dl>
          <dt>1234578<span>+</span></dt>
          <dd>每日活跃用户</dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabIndex: 0,
      slideIndex: 0,
      clickType:false,
      slideList: [
        {
          title: "数字化心理健康管理",
          img: require("../../assets/solution/medicalTreatment/swiper1.png"),
          intro:
            "Step1：根据不同人群的定制化开放不同监测方式。利用线上数字化工具，快速便 捷的进行心理初步体检。<br/>Step2：利用国内领先的情绪筛查专利技术， 通过Lxemotion情绪手环，智能生理 数据采集，客观分析受检者身心健康状态。 <br/>Step3：结合量表与情绪筛查技术给出的报告 结果，对有进一步精准预测的人群， 给予神经系统类的26项靶点检测，帮助受检者更精准的进行心理健康管理。",
        },
        {
          title: "医疗级监测报告",
          img: require("../../assets/solution/medicalTreatment/swiper2.png"),
          intro:
            "通过监测生成个人、团体医疗级监测报告，供医院各科室、心理指导师、心理咨询师多维度分析受测人员身心健康状态，进行后续干预、调节、治疗。",
        },
        {
          title: "干预与调节",
          img: require("../../assets/solution/medicalTreatment/swiper3.png"),
          intro:
            "创建健康档案管理，根据心理问题情况定义不同时间的定期随访，通知受测人员进行定期检查。医疗机构会根据受测人员情况，进行二次量表与情绪监测，系统自动生成对比报告，便与医院各科室、心理指导师、心理咨询师分析趋势与干预、调节、治疗效果。",
        },
      ],
      scrollNum: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    
    handleScroll() {
      this.scrollNum = document.documentElement.scrollTop;
      if (this.scrollNum < 1230) {
        this.tabIndex = 0;
      } else if (this.scrollNum >= 1230 && this.scrollNum < 2120) {
        this.tabIndex = 1;
      } else if (this.scrollNum >= 2120 && this.scrollNum < 3095) {
        this.tabIndex = 2;
      } else if (this.scrollNum >= 3085 && this.scrollNum < 4070) {
      // } else if (this.scrollNum >= 3085 && this.scrollNum < 3580) {
        this.tabIndex = 3;
      } else {
        this.tabIndex = 4;
      }
    },
    getCurrent(e) {},

    setCurrent(type, name) {
      if (name == "btn") {
        if (type == 1) {
          this.slideIndex == this.slideList.length - 1
            ? (this.slideIndex = 0)
            : (this.slideIndex += 1);
        } else {
          this.slideIndex == 0
            ? (this.slideIndex = this.slideList.length - 1)
            : (this.slideIndex -= 1);
        }
      } else {
        this.slideIndex = type;
      }

      this.$refs.carousel.setActiveItem(this.slideIndex);
    },

    toPage(name){
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
    clickItemBtn(index) /* 点击按钮触发 */ {
      if (index == "conRef1") {
        this.$refs.conRef1.scrollIntoView({
          behavior: "smooth",
        });
        setTimeout(()=>{
          this.tabIndex = 0;
        },500)
        
      } else if (index == "conRef2") {
        this.$refs.conRef2.scrollIntoView({
          behavior: "smooth",
        });
        setTimeout(()=>{
          this.tabIndex = 1;
        },500)
        
      } else if (index == "conRef3") {
        this.$refs.conRef3.scrollIntoView({
          behavior: "smooth",
        });
        setTimeout(()=>{
          this.tabIndex = 2;
        },500)
        
      } else {
        this.$refs.conRef4.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setTimeout(()=>{
          this.tabIndex = 3;
        },500)
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./common.less";
.banner {
  height: 500px;
  background: url(../../assets/solution/medicalTreatment/banner.png) no-repeat;
  background-size: auto 100%;
  background-position-y: bottom;
  margin-top: -58px;
  background-position-x: center;
}

.menu2Container {
  height: 613px;
  background: url(../../assets/solution/medicalTreatment/bg.png) no-repeat;
  background-size: auto 100%;
  background-position-x: center;
  .text {
    width: 1180px;
    left: 50%;
    transform: translateX(-50%);
    p {
      width: 714px !important;
      padding: 60px 32px;
      box-sizing: border-box;
    }
  }
}
</style>
<style lang='less'>
.solution {
  .el-carousel__item {
    display: flex;
    background: #fff;
    // box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);
    img {
      width: 590px;
      height: 454px;
      object-fit: cover;
    }
    .content {
      padding-top: 66px;
      padding-left: 48px;
      padding-right: 48px;
      h3 {
        margin: 0;
        font-size: 24px;
        color: #222;
        line-height: 35px;
      }
      p {
        margin: 0;
        padding-top: 24px;
        font-size: 16px;
        color: #3d3d3d;
        line-height: 32px;
      }
    }
  }
}
</style>