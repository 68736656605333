<template>
  <div class="bigscreen">
    <div class="header">
      <div class="header-address">
        <div>{{header.address}}</div>
        <div class="weather">
          <img src="../../assets/screen/icon-weather2.png" alt="" />{{header.weather}}
        </div>
        <p>{{header.temperature}}</p>
      </div>
      <div class="header-date">
        <div>{{ date }}</div>
        <div>{{ week }}</div>
        <div class="time">{{ time }}</div>
      </div>
    </div>
    <div class="container">
      <div class="container-left">
        <div class="top-title">实时数据</div>
        <div class="top-lists" v-if="frameList && frameList.data">
          <dv-scroll-board
            :config="frameList"
            style="
              margin-top: 30px;
              margin-bottom: 20px;
              width: 100%;
              height: 380px;
            "
          />
        </div>
        <div class="top-title">监测及预警趋势</div>
        <div class="bottom-data">
          <div class="data-title">实时监测情况</div>
          <div class="statistics" v-if="realTimeData[0]">
            <div class="statistics-left" >
              <p>{{(realTimeData[0][0] / realTimeData[0][1]).toFixed(2) * 100}}<i>%</i></p>
              <div>{{realTimeData[0][0]}}/{{realTimeData[0][1]}}</div>
            </div>
            <div class="statistics-right">
              <p>当日已测评/计划测评</p>
              <div class="progress">
                <div :style="'width:' + (realTimeData[0][0] / realTimeData[0][1]).toFixed(2) * 100 + '%'" class="progress-bg"></div>
                <img
                  :style="'left:' + (realTimeData[0][0] / realTimeData[0][1]).toFixed(2) * 100 + '%'"
                  src="../../assets/screen/icon-dot-blue.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="statistics statisticsG" v-if="realTimeData[1]">
            <div class="statistics-left">
              <p>{{(realTimeData[1][0] / realTimeData[1][1]).toFixed(2) * 100}}<i>%</i></p>
              <div>{{realTimeData[1][0]}}/{{realTimeData[1][0]}}</div>
            </div>
            <div class="statistics-right">
              <p>实时在线数/总监测数</p>
              <div class="progress">
                <div :style="'width:' + (realTimeData[1][0] / realTimeData[1][1]).toFixed(2) * 100 + '%'" class="progress-bg"></div>
                <img
                  :style="'left:' + (realTimeData[1][0] / realTimeData[1][1]).toFixed(2) * 100 + '%'"
                  src="../../assets/screen/icon-dot-green.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="data-title">预警趋势预测</div>
          <div class="data-charts">
            <div class="charts-tips">
              <p>单位： 人</p>
              <div>
                <span style="background: #29f1fa"></span>当日测评预警人数
              </div>
              <div>
                <span style="background: #1b7ef2"></span>当日监测预警人数
              </div>
            </div>
            <PieCharts
              :option="kLineData1"
              :style="`width: 490px; margin-left: 0%;height:126px`"
            />
          </div>
        </div>
      </div>
      <div class="container-center">
        <div class="top-list">
          <dl>
            <dt><img src="../../assets/screen/center1.png" alt="" /></dt>
            <dd>
              <span>总测评数</span>
              <p>{{ centerData.testTotal }} <span>份</span></p>
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/screen/center2.png" alt="" /></dt>
            <dd>
              <span>总关注数</span>
              <p>{{ centerData.followerTotal }} <span>人</span></p>
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/screen/center3.png" alt="" /></dt>
            <dd>
              <span>总危机数</span>
              <p>{{ centerData.crisisTotal }} <span>人</span></p>
            </dd>
          </dl>
        </div>
        <div class="map-center">
          <div
            class="address-position"
            v-for="(item, index) in addressList"
            :key="item.name"
          >
            <div
              class="address-name"
              @click="activeIndex = index"
              :style="`position:absolute;left:${item.left+10}px;top:${item.top + 6}px`"
            >
              <img
                v-if="
                  item.testTotal / 10000 >= 50 && item.testTotal / 10000 < 100
                "
                src="../../assets/screen/icon-map-blue.png"
                alt=""
              />
              <img
                v-if="
                  item.testTotal / 10000 >= 100 && item.testTotal / 10000 < 200
                "
                src="../../assets/screen/icon-map-green.png"
                alt=""
              />
              <img
                v-if="item.testTotal / 10000 >= 200"
                src="../../assets/screen/icon-map-red.png"
                alt=""
              />
              <img
                v-if="item.testTotal / 10000 < 50"
                src="../../assets/screen/icon-map-gray.png"
                alt=""
              />
              {{ item.name }}
            </div>
            <div
              :style="`position:absolute;z-index:999;left:${
                item.left - 100
              }px;top:${item.top + 18}px`"
              class="address-info"
              v-if="activeIndex == index"
            >
              <p class="name">{{ item.name }}</p>
              <i @click="activeIndex = 99"></i>
              <div>测评数：{{ item.testTotal }}</div>
              <div>总危机数：{{ item.crisisTotal }}</div>
              <div>总关注数：{{ item.followerTotal }}</div>
            </div>
          </div>
          <div class="map-tips">
            <div>
              <img src="../../assets/screen/icon-map-red.png" alt="" /> &gt;
              200（万）
            </div>
            <div>
              <img src="../../assets/screen/icon-map-green.png" alt="" />
              100 - 200（万）
            </div>
            <div>
              <img src="../../assets/screen/icon-map-blue.png" alt="" /> 50 -
              100（万）
            </div>
            <div>
              <img src="../../assets/screen/icon-map-gray.png" alt="" /> &lt; 50（万）
            </div>
          </div>
        </div>
        <div class="top-list" style="margin-top: -40px">
          <dl>
            <dt><img src="../../assets/screen/center4.png" alt="" /></dt>
            <dd>
              <span style="padding-right: 40px">服务机构</span>
              <p>{{ centerData.companyTotal }} <span>家</span></p>
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/screen/center5.png" alt="" /></dt>
            <dd>
              <span>医疗平台档案</span>
              <p>{{ centerData.medicalRecordsTotal }} <span>份</span></p>
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/screen/center6.png" alt="" /></dt>
            <dd>
              <span>对接干预人数</span>
              <p>{{ centerData.InterventionTotal }} <span>人</span></p>
            </dd>
          </dl>
        </div>
      </div>
      <div class="container-right">
        <div class="top-title">危机级别占比</div>
        <div class="high-chart">
          <div class="charts-tips-high">
            <p><span></span>一级预警 {{ optionsWarning[1].value }}%</p>
            <p class="green">
              <span></span>二级预警 {{ optionsWarning[0].value }}%
            </p>
          </div>
          <HighCharts
            v-if="optionsWarning[0].value >= 0"
            :optionData="optionsWarning"
          />
        </div>

        <div class="data-title">实时监测情况</div>
        <dv-capsule-chart
          :config="config"
          style="margin: auto; width: 400px; height: 200px"
        />
        <div class="top-title">各维度危机排名</div>
        <div class="danger-top">
          <p>焦虑</p>
          <div>{{ranking.anxiety}}<span>%</span></div>
        </div>
        <div class="danger-bottom">
          <div class="left">
            <p>压抑</p>
            <div>{{ranking.depress}}<span>%</span></div>
          </div>
          <div class="right">
            <p>压抑</p>
            <div>{{ranking.highPressure}}<span>%</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>
<script>
import PieCharts from "@/components/charts.vue";
import HighCharts from "@/components/highCharts.vue";
export default {
  components: {
    PieCharts,
    HighCharts,
  },
  data() {
    return {
      config: {
        data: [],
        unit: "%",
      },
      optionsWarning: [
        {
          name: "一级预警",
          value: '-1',
          label: {
            // name为空 不显示文本
            show: false,
          },
          labelLine: {
            // name为空 不显示指示线
            show: false,
          },
          itemStyle: {
            color: "rgba(147,219,255,0.7)",
          },
        },
        {
          name: "二级预警",
          value: '',
          label: {
            // name为空 不显示文本
            show: false,
          },
          labelLine: {
            // name为空 不显示指示线
            show: false,
          },
          itemStyle: {
            color: "rgba(83,221,158,0.7)",
          },
        },
      ],
      date: "",
      week: "",
      time: "",
      activeIndex: 0,
      // 实时数据
      frameList: {
        data: [],
        rowNum: 7,
        oddRowBGC: "",
        evenRowBGC: "",
        columnWidth: [220, 153, 166],
      },
      addressList: [
        {
          name: "北京",
          cprs: 768,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 243,
          left: 586,
        },
        {
          name: "黑龙江",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 123,
          left: 710,
        },
        {
          name: "吉林",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 183,
          left: 693,
        },
        {
          name: "辽宁",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 225,
          left: 660,
        },
        {
          name: "天津",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 260,
          left: 600,
        },
        {
          name: "河北",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 280,
          left: 565,
        },
        {
          name: "山东",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 308,
          left: 596,
        },
        {
          name: "山西",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 298,
          left: 521,
        },
        {
          name: "河南",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 345,
          left: 534,
        },
        {
          name: "江苏",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 360,
          left: 625,
        },
        {
          name: "安徽",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 392,
          left: 592,
        },
        {
          name: "上海",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 392,
          left: 648,
        },
        {
          name: "浙江",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 423,
          left: 626,
        },
        {
          name: "江西",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 439,
          left: 567,
        },
        {
          name: "福建",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 468,
          left: 600,
        },
        {
          name: "台湾",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 496,
          left: 640,
        },
        {
          name: "湖南",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 439,
          left: 510,
        },
        {
          name: "湖北",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 390,
          left: 520,
        },
        {
          name: "广东",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 500,
          left: 540,
        },
        {
          name: "内蒙古",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 229,
          left: 460,
        },
        {
          name: "宁夏",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 293,
          left: 448,
        },
        {
          name: "陕西",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 344,
          left: 473,
        },
        {
          name: "重庆",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 410,
          left: 457,
        },
        {
          name: "贵州",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 460,
          left: 452,
        },
        {
          name: "广西",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 500,
          left: 472,
        },
        {
          name: "海南",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 560,
          left: 495,
        },
        {
          name: "甘肃",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 243,
          left: 314,
        },
        {
          name: "青海",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 322,
          left: 265,
        },
        {
          name: "四川",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 403,
          left: 380,
        },
        {
          name: "云南",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 482,
          left: 377,
        },
        {
          name: "新疆",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 260,
          left: 120,
        },
        {
          name: "西藏",
          cprs: 7683,
          number: 500,
          wjrs: 20,
          sbsl: 500023,
          xlda: 5678890,
          top: 380,
          left: 205,
        },
      ],
      kLineData1: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              backgroundColor: "#ccc",
              borderColor: "#aaa",
              borderWidth: 1,
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              textStyle: {
                color: "#222",
              },
            },
          },
          axisLine: {
            //x轴线设置
            lineStyle: {
              type: "dashed",
              color: "rgba(255,255,255,0.04)", //x线的颜色
              width: "1", //坐标线的宽度
            },
          },
        },
        grid: {
          top: "10px",
          left: "0px",
          right: "10px",
          bottom: "20px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          show: true,
          boundaryGap: false,
          data: ['昨日','今日','明日'],
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,0.64)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,0.64)",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: ["rgba(255,255,255,0.04)"],
            },
          },
          axisLine: {
            //x轴线设置
            lineStyle: {
              type: "dashed",
              color: "rgba(255,255,255,0.04)", //x线的颜色
              width: "1", //坐标线的宽度
            },
          },
        },
        series: [
          {
            name: "当日测评预警人数",
            type: "line",
            smooth: true,
            data: [],
            symbol: "none",

            lineStyle: {
              color: "#29F1FA",
              width: 1,
            },
            itemStyle: {
              color: "#F56C6C",
              borderColor: "#B3B2B3",
              borderWidth: 1,
            },
            smooth: true,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(18,156,255,0.32)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(18,156,255,0.21)", //
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
          {
            name: "当日监测预警人数",
            type: "line",
            smooth: true,
            data: [],
            symbol: "none",

            lineStyle: {
              color: "#1B7EF2",
              width: 1,
            },
            itemStyle: {
              color: "#F56C6C",
              borderColor: "#1B7EF2",
              borderWidth: 1,
            },
            smooth: true,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(11,197,197,0.32)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(11,197,197,0.21)", //
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      },
      centerData: {
        InterventionTotal: "",
        companyTotal: "",
        crisisTotal: "",
        followerTotal: "",
        medicalRecordsTotal: "",
        testTotal: "",
      },
      header:{
        address:'',
        weather:'',
        temperature:''
      },
      realTimeData:[],
      ranking:{
        anxiety:'',
        depress:'',
        highPressure:''
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.getNowTime();
    setInterval(() => {
      this.getNowTime();
    }, 1000);
    setInterval(() => {
      this.activeIndex =
        this.activeIndex >= this.addressList.length - 1
          ? 0
          : (this.activeIndex += 1);
    }, 20000);
  },
  methods: {
    async getList() {
      this.$axios.get("https://luyan.lxemotion.com/map.json").then((res) => {
        console.log(res.data.left.baseData);
        this.header = res.data.header
        this.frameList = {
          data: res.data.left.baseData,
          rowNum: 7,
          oddRowBGC: "",
          evenRowBGC: "",
          columnWidth: [220, 153, 166],
        };
        this.kLineData1.series[0].data = res.data.left.earlyWarningTrend[0];
        this.kLineData1.series[1].data = res.data.left.earlyWarningTrend[1];
        this.addressList = res.data.center.map;
        this.centerData = res.data.center.other;
        this.config = {data:res.data.right.gradeStatistics, unit: "%", }
        this.optionsWarning[0].value = res.data.right.warningPercent.level1
        this.optionsWarning[1].value = res.data.right.warningPercent.level2
        this.realTimeData = res.data.left.realTimeData
        this.ranking = res.data.right.ranking
      });
    },
    getNowTime() {
      var date = new Date();
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1;
      //日 getDate()：(1 ~ 31)
      var day = date.getDate();
      //时 getHours()：(0 ~ 23)
      var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      //分 getMinutes()： (0 ~ 59)
      var minute =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      //秒 getSeconds()：(0 ~ 59)
      var second =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      this.date = year + "." + month + "." + day;
      this.time = hour + ":" + minute + ":" + second;
      // 获取星期几
      const weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      this.week = weeks[new Date().getDay()];
    },
  },
};
</script>
<style lang="less" scoped>
.bigscreen {
  width: 100%;
  height: 1080px;
  overflow: hidden;
  background: #010308;
  .header {
    background: url(../../assets/screen/header.png) no-repeat 100%;
    background-size: 100% auto;
    height: 75px;
    display: flex;
    align-items: center;
    .header-address,
    .header-date {
      flex: 1;
      display: flex;
      padding: 0 20px;
      align-items: center;
    }
    .header-address {
      div {
        font-size: 24px;
        color: #fff;
        margin-right: 60px;
      }
      div.weather {
        display: flex;
        margin-left: 4px;
        align-items: center;
        font-size: 18px;
        color: #45a2ff;
        img {
          width: 36px;
          margin-right: 16px;
        }
      }
      p {
        margin: 0;
        font-size: 24px;
        color: #45a2ff;
      }
    }
    .header-date {
      justify-content: right;
      div {
        margin-left: 60px;
        font-size: 24px;
        color: #45a2ff;
      }
      .time {
        width: 120px;
        color: #fff;
        font-size: 24px;
      }
    }
  }
  .container {
    padding: 20px 30px 0;
    background: url(../../assets/screen/container-bg.jpg) no-repeat #010308;
    background-position-y: 72px;
    background-size: 100% auto;
    display: flex;
    .container-left {
      width: 29.3%;
      .top-title {
        background: url(../../assets/screen/container-title-bg.png) no-repeat;
        background-size: 100% 50px;
        line-height: 50px;
        font-size: 24px;
        padding-left: 30px;
        box-sizing: border-box;
        color: #fff;
      }
      .bottom-data {
        padding-left: 24px;
        padding-top: 15px;
        .data-title {
          background: url(../../assets/screen/data-title.png) no-repeat;
          background-size: auto 40px;
          line-height: 40px;
          box-sizing: border-box;
          font-size: 22px;
          padding-left: 36px;
          color: #29f1fa;
        }
        .statistics {
          display: flex;
          .statistics-left {
            width: 124px;
            height: 76px;
            background: url(../../assets/screen/statistics-blue.png) no-repeat;
            background-size: 100% 100%;
            p {
              margin: 0;
              text-align: center;
              line-height: 22px;
              font-size: 18px;
              padding-top: 5px;
              color: #fff;
              i {
                color: #fff;
                font-style: normal;
                font-size: 14px;
              }
            }
            div {
              font-size: 15px;
              line-height: 14px;
              color: #fff;
              text-align: center;
            }
          }
          .statistics-right {
            flex: 1;
            padding-right: 36px;
            p {
              margin: 0;
              font-size: 22px;
              color: #fff;
              line-height: 31px;
            }
            .progress {
              width: 100%;
              margin-top: 12px;
              height: 8px;
              background: rgba(112, 112, 112, 0.23);
              position: relative;
              .progress-bg {
                position: absolute;
                height: 100%;
                background: linear-gradient(
                  259deg,
                  #1b7ef2 0%,
                  rgba(27, 126, 242, 0) 100%
                );
              }
              img {
                position: absolute;
                margin-left: -16px;
                width: 32px;
                height: 32px;
                top: -12px;
              }
            }
          }
        }
        .statisticsG {
          margin-top: 5px;
          margin-bottom: 3px;
          .statistics-left {
            background: url(../../assets/screen/statistics-green.png) no-repeat;
            background-size: 100% 100%;
          }
          .statistics-right {
            .progress {
              .progress-bg {
                background: linear-gradient(
                  259deg,
                  #29f1fa 0%,
                  rgba(41, 241, 250, 0) 100%
                );
              }
            }
          }
        }
      }
      .data-charts {
        margin-top: 4px;
        .charts-tips {
          display: flex;
          height: 28px;
          align-items: center;
          p {
            margin: 0;
            width: 92px;
            color: rgba(255, 255, 255, 0.64);
            font-size: 20px;
          }
          div {
            font-size: 20px;
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, 0.64);
            margin-left: 20px;
            span {
              display: inline-block;
              width: 18px;
              height: 2px;
              margin-right: 4px;
            }
          }
        }
      }
    }
    .container-center {
      width: 45%;
      padding-top: 3px;
      margin-left: -15px;
      .top-list {
        display: flex;
        height: 72px;
        align-items: center;
        dl {
          margin: 0;
          flex: 1;
          display: flex;
          height: 72px;
          align-items: center;
          dt {
            margin: 0;
            width: 72px;
            height: 72px;
            img {
              width: 72px;
              float: left;
              height: 72px;
            }
          }
          dd {
            height: 72px;
            margin: 0 0 0 16px;
            flex: 1;
            span {
              font-size: 20px;
              float: left;
              color: rgba(255, 255, 255, 0.64);
              line-height: 28px;
            }
            p {
              font-weight: 700;
              font-size: 28px;
              float: left;
              line-height: 40px;
              color: #fff;
              margin: 0;
              span {
                float: right;
                line-height: 40px;
                font-size: 20px;
                padding-right: 0;
                font-weight: normal;
              }
            }
          }
        }
      }
      .map-center {
        height: 780px;
        position: relative;
        .address-position {
          // position: absolute;
          .address-name {
            display: flex;
            line-height: 26px;
            cursor: pointer;
            align-items: center;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.87);
            img {
              width: 24px;
              height: 26px;
            }
          }
          .address-info {
            width: 193px;
            height: 125px;
            background: url(../../assets/screen/address-info.png) no-repeat;
            background-size: 100% auto;
            padding: 18px 0 0 17px;
            .name {
              font-size: 14px;
              margin: 0;
              color: #29f1fa;
              line-height: 18px;
              margin-bottom: 10px;
            }
            i {
              display: inline-block;
              width: 20px;
              height: 20px;
              right: 15px;
              top: 20px;
              cursor: pointer;
              position: absolute;
            }
            div {
              font-size: 12px;
              line-height: 15px;
              margin-bottom: 4px;
              margin-top: 6px;
              color: #fff;
            }
          }
        }
        .map-tips {
          width: 350px;
          display: flex;
          flex-wrap: wrap;
          position: absolute;
          bottom: 80px;
          left: 45px;
          justify-content: space-between;
          div {
            width: 175px;
            display: flex;
            align-items: center;
            margin-bottom: 17px;
            font-size: 18px;
            line-height: 18px;
            color: rgba(255, 255, 255, 0.64);
            img {
              width: 26px;
              margin-right: 4px;
            }
          }
        }
      }
    }
    .container-right {
      width: 29.3%;
      .top-title {
        background: url(../../assets/screen/container-title-bg.png) no-repeat;
        background-size: 100% 50px;
        line-height: 50px;
        font-size: 24px;
        padding-left: 30px;
        box-sizing: border-box;
        color: #fff;
      }
      .high-chart {
        display: flex;
        .charts-tips-high {
          padding-left: 65px;
          flex-shrink: 0;
          p {
            display: flex;
            align-items: center;
            margin: 0;
            margin-top: 52px;
            font-size: 18px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.64);
            span {
              display: inline-block;
              margin-right: 4px;
              width: 10px;
              height: 10px;
              background: #9cbecf;
            }
          }
          p.green {
            margin-top: 30px;
            span {
              background: #49c384;
            }
          }
        }
      }

      .data-title {
        background: url(../../assets/screen/data-title.png) no-repeat;
        background-size: auto 40px;
        line-height: 40px;
        box-sizing: border-box;
        font-size: 22px;
        margin-top: 10px;
        margin-left: 54px;
        padding-left: 36px;
        color: #29f1fa;
      }

      .danger-top {
        width: 264px;
        height: 264px;
        margin-top: -42px;
        background: url(../../assets/screen/danger-orange.png) no-repeat;
        background-size: 100% 100%;
        margin: auto;
        text-align: center;
        p {
          margin: 0;
          font-size: 28px;
          color: #f2994a;
          padding-top: 97px;
          line-height: 25px;
        }
        div {
          display: flex;
          margin-top: 5px;
          align-items: flex-end;
          font-size: 32px;
          text-align: center;
          height: 40px;
          color: rgba(255, 255, 255, 0.87);
          font-weight: 900;
          justify-content: center;
          span {
            padding-left: 5px;
            font-size: 20px;
            color: #f2994a;
          }
        }
      }
      .danger-bottom {
        display: flex;
        justify-content: center;
        .left {
          width: 264px;
          height: 264px;
          margin-top: -89px !important;
          background: url(../../assets/screen/danger-blue.png) no-repeat;
          background-size: 100% 100%;
          margin: auto;
          text-align: center;
          p {
            margin: 0;
            font-size: 28px;
            color: #1b7ef2;
            padding-top: 97px;
            line-height: 25px;
          }
          div {
            display: flex;
            margin-top: 5px;
            align-items: flex-end;
            font-size: 32px;
            text-align: center;
            height: 40px;
            color: rgba(255, 255, 255, 0.87);
            font-weight: 900;
            justify-content: center;
            span {
              padding-left: 5px;
              font-size: 20px;
              color: #1b7ef2;
            }
          }
        }
        .right {
          width: 264px;
          height: 264px;
          margin-top: -84px !important;
          margin-left: -63px !important;
          background: url(../../assets/screen/danger-red.png) no-repeat;
          background-size: 100% 100%;
          margin: auto;
          text-align: center;
          p {
            margin: 0;
            font-size: 28px;
            color: #bc4747;
            padding-top: 97px;
            line-height: 25px;
          }
          div {
            display: flex;
            margin-top: 5px;
            align-items: flex-end;
            font-size: 32px;
            text-align: center;
            height: 40px;
            color: rgba(255, 255, 255, 0.87);
            font-weight: 900;
            justify-content: center;
            span {
              padding-left: 5px;
              font-size: 20px;
              color: #bc4747;
            }
          }
        }
      }
    }
  }
  .footer {
    height: 18px;
    background: url(../../assets/screen/footer.png) no-repeat;
    background-size: auto 18px;
    background-position-x: center;
    margin-top: -18px;
  }
}
</style>