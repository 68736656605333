<template>
  <div class="xfxl">
    <div class="banner">
      <div class="bannerContainer">
        <div class="left">
          <div class="txt">心理服务咨询平台</div>
          <p>
            <span
              >平台汇集了8000+专业的心理学家、心理咨询师、心理教育师等资源，为用户提供权威的心理指导和支持</span
            >
          </p>
          <div class="banner-btn">
            <em @click="clickTopBtn()">立即扫码进入</em>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/product/xfxl/banner-right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="xfxlContainer">
      <div class="top">
        <h3>我们的优势</h3>
        <div class="intro">科技、普惠、保障型心理健康服务体系</div>
        <div class="lists">
          <dl>
            <dt><img src="../../assets/product/xfxl/icon1.png" alt="" /></dt>
            <dd>
              <p>专业化</p>
              <span
                >专业的心理健康平台，提供全方位的心理健康科普、服务、咨询等内容</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/xfxl/icon2.png" alt="" /></dt>
            <dd>
              <p>人才储备</p>
              <span
                >拥有8000+专业的心理学家、心理咨询师、心理教育师等资源，为用户提供权威的心理指导和支持</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/xfxl/icon3.png" alt="" /></dt>
            <dd>
              <p>多样化</p>
              <span
                >持在线或线下的心理咨询、指导、倾听、讲座等服务，满足用户不同的需求和偏好</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/xfxl/icon4.png" alt="" /></dt>
            <dd>
              <p>包容性</p>
              <span
                >支持全国心理机构入驻平台，致力于打造心理服务联合体，帮助全国用户了解自己的心理状况</span
              >
            </dd>
          </dl>
        </div>
      </div>
      <div class="bottom">
        <div class="intro">
          <div class="lists">
            <dl class="left">
              <dt><img src="../../assets/product/xfxl/list1.png" alt="" /></dt>
              <dd>
                <p>微信小程序方便快捷</p>
                <div>
                  <i></i>
                  <span>微信登录无需注册</span>
                </div>
                <div>
                  <i></i>
                  <span>一键智能匹配专家</span>
                </div>
                <div>
                  <i></i>
                  <span>沟通快捷随用随走</span>
                </div>
              </dd>
            </dl>
            <div class="right">
              <p class="one">微信支付<img src="../../assets/product/xfxl/list-icon-1.png" alt=""></p>
              <p class="two">支付确定<img src="../../assets/product/xfxl/list-icon-2.png" alt=""></p>
              <p class="three">用户注册<img src="../../assets/product/xfxl/list-icon-3.png" alt=""></p>
              <p class="four">找回密码<img src="../../assets/product/xfxl/list-icon-4.png" alt=""></p>
              <p class="five">信息变更<img src="../../assets/product/xfxl/list-icon-5.png" alt=""></p>
              <p class="six">开通功能<img src="../../assets/product/xfxl/list-icon-6.png" alt=""></p>
              <img src="../../assets/product/xfxl/list1-phone.png" alt="" />
            </div>
          </div>
        </div>

        <div class="intro">
          <div class="lists">

            <div class="right rightTwo">
              <p class="one">心理咨询<img src="../../assets/product/xfxl/list2-icon-1.png" alt=""></p>
              <p class="two">倾诉吐槽<img src="../../assets/product/xfxl/list2-icon-2.png" alt=""></p>
              <p class="three">心理指导<img src="../../assets/product/xfxl/list2-icon-3.png" alt=""></p>
              <p class="four">专家讲座<img src="../../assets/product/xfxl/list2-icon-4.png" alt=""></p>
              <p class="five">智能匹配<img src="../../assets/product/xfxl/list2-icon-5.png" alt=""></p>
              <p class="six">专家众多<img src="../../assets/product/xfxl/list2-icon-6.png" alt=""></p>
              <img src="../../assets/product/xfxl/list2-phone.png" alt="" />
            </div>
            <dl class="left">
              <dt><img src="../../assets/product/xfxl/list2.png" alt="" /></dt>
              <dd>
                <p>及时解决用户的心理需求</p>
                <div>
                  <i></i>
                  <span>多种心理服务，满足用户多种需求</span>
                </div>
                <div>
                  <i></i>
                  <span>可以有更多老师入驻到平台</span>
                </div>
                <div>
                  <i></i>
                  <span>拥有更智能的定制化需求</span>
                </div>
              </dd>
            </dl>
          </div>
        </div>

        <div class="intro">
          <div class="lists">
            <dl class="left">
              <dt><img src="../../assets/product/xfxl/list3.png" alt="" /></dt>
              <dd>
                <p>专家老师入驻方便快捷</p>
                <div>
                  <i></i>
                  <span>订单状态实时展示</span>
                </div>
                <div>
                  <i></i>
                  <span>服务包专推引流用户</span>
                </div>
                <div>
                  <i></i>
                  <span>工作区域一目了然</span>
                </div>
              </dd>
            </dl>
            <div class="right">
              <p class="one">服务包订单<img src="../../assets/product/xfxl/list3-icon-1.png" alt=""></p>
              <p class="two">我的账户<img src="../../assets/product/xfxl/list3-icon-2.png" alt=""></p>
              <p class="three">服务设置<img src="../../assets/product/xfxl/list3-icon-3.png" alt=""></p>
              <p class="four">排班设置<img src="../../assets/product/xfxl/list3-icon-4.png" alt=""></p>
              <p class="five">消息通知<img src="../../assets/product/xfxl/list3-icon-5.png" alt=""></p>
              <p class="six">资料认证<img src="../../assets/product/xfxl/list3-icon-6.png" alt=""></p>
              <img src="../../assets/product/xfxl/list3-phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{

clickTopBtn() {
   let dom = document.documentElement;
   window.scrollTo({ behavior: 'smooth', top: dom.scrollHeight });
},
  }
};
</script>
<style lang="less" scoped>
.banner {
  background: url(../../assets/about/banner.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  height: 500px;
  margin-top: -58px;
  overflow: hidden;
  position: relative;
  .bannerContainer {
    width: 1180px;
    justify-content: space-between;
    display: flex;
    margin: auto;
    .left {
      width: 521px;
      flex-shrink: 0;
      .txt {
        font-size: 44px;
        line-height: 64px;
        color: #222;
        position: relative;
        margin: auto;
        padding-top: 138px;
      }

      p {
        font-size: 16px;
        line-height: 23px;
        margin: 24px auto 0;

        span {
          display: block;
          width: 521px;
          color: #222;
        }
      }

      .banner-btn {
        margin: 0 auto 0;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 87px;
        em {
          display: block;
          font-style: normal;
          cursor: pointer;
          background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
          width: 190px;
          height: 52px;
          border-radius: 30px;
          text-align: center;
          line-height: 52px;
          color: #0065ff;
          font-size: 16px;
        }

        img {
          width: 107px;
          margin-left: 30px;
          height: 107px;
        }
      }
    }
    .right {
      padding-top: 148px;
      display: flex;
      justify-content: flex-end;
      img {
        width: 420px;
      }
    }
  }
}

.xfxlContainer {
  background: url(../../assets/product/bg.png) no-repeat;
  background-size: 100% auto;
  background-position-y: top;
  .top {
    padding: 84px 0 80px;
    width: 1180px;
    margin: 0 auto;
    .intro {
      color: #798a94;
      line-height: 29px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 80px;
    }
    .lists {
      display: flex;
      dl {
        flex: 1;
        margin: 0 95px 0 0;
        text-align: center;
        dt {
          width: 50px;
          height: 50px;
          margin: 0 auto;
          img {
            width: 100%;
            float: left;
            height: 100%;
          }
        }
        dd {
          margin: 0;
          p {
            margin: 14px 0 9px;
            text-align: center;
            font-size: 20px;
            line-height: 29px;
            color: #3d3d3d;
          }
          span {
            display: block;
            line-height: 20px;
            text-align: left;
            color: #3d3d3d;
            font-size: 14px;
          }
        }
      }
      dl:last-child {
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 34px;
    color: #011739;
    line-height: 49px;
    margin: 0 0 12px;
    text-align: center;
  }
  .bottom {
    text-align: center;
    .intro {
      background: rgba(0, 101, 255, 0.06);
      padding: 80px 0;
      .lists {
        display: flex;
        align-items: center;
        max-width: 1180px;
        margin: 0 auto;
        justify-content: space-between;
        .left {
          width: 350px;
          text-align: center;
          margin: 0;
          img {
            width: 350px;
          }
          dd {
            margin-top: -16px;
            p {
              font-size: 22px;
              line-height: 32px;
              color: #3d3d3d;
              margin: 0;
              text-align: center;
            }
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              color: #666;
              i {
                display: inline-block;
                width: 12px;
                height: 12px;
                background: #0065ff;
                border-radius: 6px;
                margin-right: 12px;
              }
              span {
                font-size: 16px;
                line-height: 23px;
              }
            }
          }
        }
        .right{
          width: 602px;
          position: relative;
          height: 600px;
          img{
            float: right;
          }
          p{
            font-size: 22px;
            line-height: 34px;
            display: flex;
            position: absolute;
            align-items: center;
            margin: 0;
            img{
              width: 34px;
              height: 34px;
              margin-left: 26px;
            }
          }
          .one{
            left: 84px;
            top: 71px;
          }
          .two{
            left: 54px;
            top: 156px;
          }
          .three{
            left: 0;
            top: 244px;
          }
          .four{
            left: 0;
            top: 327px;
          }
          .five{
            left: 54px;
            top: 411px;
          }
          .six{
            top: 496px;
            left: 84px;
          }
        }
        .rightTwo{
          img{
            float: left;
          }
          .one,.six{
            left: 355px;
          }
          .two,.five{
            left: 383px;
          }
          .three,.four{
            left: 437px;
          }
        }
      }
    }
    .intro:nth-child(2) {
      background: none;
      .lists {
        dl {
          dt {
            img {
              width: 260px;
            }
          }
          dd {
            margin-top: 64px;
            div {
              width: 264px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }
        }
      }
    }
    .intro:nth-child(3) {
      .lists {
        dl {
          dt {
            img {
              width: 287px;
            }
          }
          dd {
            margin-top: 59px;
            div {
              margin: auto;
              width: 200px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}
</style>