<template>
  <div class="enterprise">
    <div class="banner">
      <div class="txt">政企应用场景</div>
      <p>
        <span
          >为企业提供整体解决方案，帮助企业搭建心理驿站、专兼职心理服务队伍、心理服务体系，助力建设社会心理服务体系。</span
        >
      </p>
      <div class="banner-btn" @click="toPage('reservation')"><em>立即预约演示</em></div>
    </div>
    <div
      class="tabs"
      v-show="tabIndex != 4"
      :style="
        scrollNum > 417
          ? 'position:fixed;top:0px;width:100%;z-index:2'
          : 'position:relative'
      "
    >
      <div :style="scrollNum > 417 ? 'top:0;width:100%' : ''">
        <p
          @click="clickItemBtn('conRef1')"
          :class="tabIndex == 0 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 0 ? 'background:#0065FF;color:#fff' : ''"
        >
          方案优势
        </p>
        <p
          @click="clickItemBtn('conRef2')"
          :class="tabIndex == 1 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 1 ? 'background:#0065FF;color:#fff' : ''"
        >
          解决方案介绍
        </p>
        <p
          @click="clickItemBtn('conRef3')"
          :class="tabIndex == 2 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 2 ? 'background:#0065FF;color:#fff' : ''"
        >
          解决方案全景
        </p>
        <p
          @click="clickItemBtn('conRef4')"
          :class="tabIndex == 3 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 3 ? 'background:#0065FF;color:#fff' : ''"
        >
          客户案例
        </p>
      </div>
    </div>
    <div
      class="tabMenu1"
      :style="scrollNum > 417 ? 'padding-top:60px' : ''"
      ref="conRef1"
    >
     <div class="bigBox">
      <div class="title">方案优势</div>
      <div class="intro">让心理健康成为互联网心动力</div>
      <div class="slide">
        <p
          v-for="(item, index) in slideList"
          :key="index"
          @click="setCurrent(index, 'tab')"
          :class="slideIndex == index ? 'active' : ''"
        >
          {{ item.title }}
        </p>
      </div>
      <div class="slideIntro">
        <el-carousel
          ref="carousel"
          :autoplay="false"
          height="454px"
          @change="getCurrent"
          indicator-position="none"
          arrow="never"
        >
          <el-carousel-item v-for="item in slideList" :key="item.name">
            <img :src="item.img" alt="" srcset="" />
            <div class="content">
              <h3>{{ item.title }}</h3>
              <p>
                {{ item.intro }}
              </p>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="slideBtn">
          <div class="last" @click="setCurrent(-1, 'btn')">&lt;</div>
          <div class="next" @click="setCurrent(1, 'btn')">&gt;</div>
        </div>
      </div>
     </div>
    </div>
    <div class="tabMenu2" ref="conRef2">
      <div class="title" v-animate="'move-up'">解决方案介绍</div>
      <div class="intro" v-animate="'move-up'">为政府企业排忧解难</div>
      <div class="menu2Container">
        <div class="text">
          <p>
            建成以物联网智能感知技术为依托的广泛智能感知，立体多维监测，实时智能反馈，自动报告预警，全面分析的政企心理感知监测评估及危机预警系统，为政企群体心理健康安全管理，政企群体心理健康认知提升及管理，提供智能化支持，提供科学化、智能化、超前可控化水平，实现真正的“智慧心
            理健康”型现代化智慧解决方案。
          </p>
        </div>
      </div>
    </div>
    <div class="tabMenu3" ref="conRef3">
      <div class="title" v-animate="'move-up'">解决方案全景</div>
      <div class="intro" v-animate="'move-up'">架构完善，实现更复杂的场景问题解决</div>
      <div class="menu3Container">
        <div class="top">
          <div class="left">
            <div>
              <img src="../../assets/solution/icon-menu.png" alt="" />
              <p>解决方案</p>
            </div>
          </div>
          <div class="right">
            <dl>
              <dt>政企解决方案</dt>
              <dd>司法丨军工丨交通</dd>
            </dl>
            <dl>
              <dt>教育解决方案</dt>
              <dd>K12院校丨幼儿园丨泛教育丨教育局</dd>
            </dl>
            <dl>
              <dt>医疗解决方案</dt>
              <dd>互联网医院丨医疗结构丨体检机构</dd>
            </dl>
          </div>
        </div>
        <div class="center">
          <div class="left">
            <div>
              <img src="../../assets/solution/icon-menu.png" alt="" />
              <p>应用平台</p>
            </div>
          </div>
          <div class="right">
            <dl>
              <dt>心理情绪监测系统</dt>
              <dd>
                <p>公有云/私有云</p>
                <p>混合云部署</p>
              </dd>
              <dd>
                <p>运维保障</p>
                <p>定制开发</p>
              </dd>
            </dl>

            <dl>
              <dt>数据可视化监控平台</dt>
              <dd>
                <p>多源异构数据融合</p>
              </dd>
              <dd>
                <p>大数据统计分析</p>
                <p>定制开发</p>
              </dd>
            </dl>
            <dl>
              <dt>心态预警与评估系统</dt>
              <dd>
                <p>风险等级预警</p>
              </dd>
              <dd>
                <p>智能短信</p>
                <p>智能外呼</p>
              </dd>
            </dl>

            <dl>
              <dt>心理服务站信息化</dt>
              <dd>
                <p>智能舒心仓</p>
                <p>可视化数据大屏</p>
              </dd>
              <dd>
                <p>放松椅</p>
                <p>智能化空间建设</p>
              </dd>
            </dl>

            <dl>
              <dt>幸福心联服务平台</dt>
              <dd>
                <p>心理需求发布</p>
                <p>心理人才在线培训</p>
              </dd>
              <dd>
                <p>在线心理服务</p>
                <p>心理人才考试系统</p>
              </dd>
            </dl>

            <dl>
              <dt>Seemo(犀陌)移动端</dt>
              <dd>
                <p>健康体征监测</p>
                <p>社区通讯</p>
              </dd>
              <dd>
                <p>在线心理咨询</p>
                <p>电商</p>
              </dd>
            </dl>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            <div>
              <img src="../../assets/solution/icon-menu.png" alt="" />
              <p>底层服务</p>
            </div>
          </div>
          <div class="right">
            <div class="rightTop">
              <p>云原生</p>
              <p>心理情绪AI算法</p>
              <p>大数据</p>
              <p>AIGC</p>
              <p>边缘计算</p>
              <p>物联网</p>
            </div>
            <div class="rightBottom">
              <p>计算服务</p>
              <p>储存服务</p>
              <p>网络服务</p>
              <p>基础服务</p>
              <p>硬件服务</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabMenu4" ref="conRef4">
      <div class="title" v-animate="'move-up'">客户案例</div>
      <div class="intro" v-animate="'move-up'">专属数据解决方案与服务，伴随客户持续成长</div>
      <div class="menu4Container">
        <dl>
          <dt><img src="../../assets/solution/enterprise/news1.png" alt="" /></dt>
          <dd>
            <p class="name">北京民政局</p>
            <p class="msg">
              打造从日常科普、 自我心理调整、心理预防、社 会心理服务培训，到个案疏导 形成多方位一体化的企业心理 健康平台，为员工提供普及性、 普遍性、普惠性的心理健康服务。
            </p>
            <div class="type">
              <span>政企</span>
            </div>
          </dd>
        </dl>
        <dl>
          <dt><img src="../../assets/solution/enterprise/news2.png" alt="" /></dt>
          <dd>
            <p class="name">中华全国总工会</p>
            <p class="msg">
              中华全国总工会，中国共产党领导的中国工人阶级的群众组织。 中华全国总工会是中国共产党领导的职工自愿结合的工人阶级群众组织，由中国共产党中央委员会书记处领导。
            </p>
            <div class="type">
              <span>政企</span>
            </div>
          </dd>
        </dl>
        <dl>
          <dt><img src="../../assets/solution/enterprise/news3.png" alt="" /></dt>
          <dd>
            <p class="name">中交集团</p>
            <p class="msg">
              中交集团是一家全球领先的综合性交通基础设施建设企业，重视员工的心理健康服务是集团持续发展的重要策略之一。心理健康对员工的工作表现和创造力有着至关重要的影响，也为企业带来许多潜在的商业利益。因此，中交集团致力于提供全方位的心理健康服务，以支持员工身心健康，提高工作满意度和绩效。
            </p>
            <div class="type">
              <span>政企</span>
            </div>
          </dd>
        </dl>
      </div>
      <div class="menu4Number">
        <dl>
          <dt>13417<span>+</span></dt>
          <dd>项目广泛应用</dd>
        </dl>
        <dl>
          <dt>237<span>+</span></dt>
          <dd>客户共同选择</dd>
        </dl>
        <dl>
          <dt>1234578<span>+</span></dt>
          <dd>每日活跃用户</dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabIndex: 0,
      slideIndex: 0,
      clickType:false,
      slideList: [
        {
          title: "新型化科普",
          img: require("../../assets/solution/enterprise/swiper1.png"),
          intro:
            "我们将采用新型化科普措施，通过创新、易懂的方式向公众普及心理健康知识，提高人们对心理问题的认知。",
        },
        {
          title: "档案化管理系统",
          img: require("../../assets/solution/enterprise/swiper2.png"),
          intro:
            "引入档案化管理系统，建立个人心理健康档案，记录个体心理状况、干预过程以及效果，以便进行更有效的评估和干预。",
        },
        {
          title: "情绪监测系统",
          img: require("../../assets/solution/enterprise/swiper3.png"),
          intro:
            "我们将开发心理健康应用程序和在线平台，让人们能够自主进行自我评估、获取专业建议、并获得个性化的心理干预方案。高效化筛查是关键环节，通过情绪监测系统，及早发现政企场景人群潜在的心理问题，实现早期干预。",
        },
        {
          title: "数字化报告",
          img: require("../../assets/solution/enterprise/swiper4.png"),
          intro:
            "数字化报告和情绪监测系统将帮助专业人士更准确地评估个体心理状态和心理健康进展，为干预过程提供实时数据支持。",
        },
        {
          title: "社会心理指导师人才培养",
          img: require("../../assets/solution/enterprise/swiper5.png"),
          intro:
            "为了提高心理健康服务的专业化水平，实施体系化人才培养，培养更多的心理健康专业人才，满足不断增长的心理健康服务需求。",
        },
        {
          title: "社会心理服务站",
          img: require("../../assets/solution/enterprise/swiper6.png"),
          intro:
            "打造北京模式化400家社会心理服务站提供多样化的心理健康服务，覆盖社区、街道等，让心理健康服务走进大众生活。还将定期举办心理健康讲座和心理督导，向公众传授心理保健知识和应对技巧，增强心理健康意识和抵抗力与帮助社会心理指导师快速获取心理指导经验。",
        },
      ],
      scrollNum: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    
    handleScroll() {
      this.scrollNum = document.documentElement.scrollTop;
      if (this.scrollNum < 1230) {
        this.tabIndex = 0;
      } else if (this.scrollNum >= 1230 && this.scrollNum < 2120) {
        this.tabIndex = 1;
      } else if (this.scrollNum >= 2120 && this.scrollNum < 3095) {
        this.tabIndex = 2;
      } else if (this.scrollNum >= 3085 && this.scrollNum < 4070) {
      // } else if (this.scrollNum >= 3085 && this.scrollNum < 3580) {
        this.tabIndex = 3;
      } else {
        this.tabIndex = 4;
      }
    },
    getCurrent(e) {},

    setCurrent(type, name) {
      if (name == "btn") {
        if (type == 1) {
          this.slideIndex == this.slideList.length - 1
            ? (this.slideIndex = 0)
            : (this.slideIndex += 1);
        } else {
          this.slideIndex == 0
            ? (this.slideIndex = this.slideList.length - 1)
            : (this.slideIndex -= 1);
        }
      } else {
        this.slideIndex = type;
      }

      this.$refs.carousel.setActiveItem(this.slideIndex);
    },

    toPage(name){
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
    clickItemBtn(index) /* 点击按钮触发 */ {
      if (index == "conRef1") {
        this.$refs.conRef1.scrollIntoView({
          behavior: "smooth",
        });
        setTimeout(()=>{
          this.tabIndex = 0;
        },500)
        
      } else if (index == "conRef2") {
        this.$refs.conRef2.scrollIntoView({
          behavior: "smooth",
        });
        setTimeout(()=>{
          this.tabIndex = 1;
        },500)
        
      } else if (index == "conRef3") {
        this.$refs.conRef3.scrollIntoView({
          behavior: "smooth",
        });
        setTimeout(()=>{
          this.tabIndex = 2;
        },500)
        
      } else {
        this.$refs.conRef4.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setTimeout(()=>{
          this.tabIndex = 3;
        },500)
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./common.less";
.banner {
  height: 500px;
  background: url(../../assets/solution/enterprise/banner.png) no-repeat;
  background-size: auto 100%;
  background-position-y: bottom;
  margin-top: -58px;
  background-position-x: center;
}

.menu2Container {
  height: 613px;
  background: url(../../assets/solution/enterprise/bg.png) no-repeat;
  background-size: auto 100%;
  background-position-x: center;
  .text {
    width: 1180px;
    left: 50%;
    transform: translateX(-50%);
    p {
      width: 714px !important;
      padding: 60px 32px;
      box-sizing: border-box;
    }
  }
}
</style>
<style lang='less'>
.solution {
  .el-carousel__item {
    display: flex;
    background: #fff;
    // box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);
    img {
      width: 590px;
      height: 454px;
      object-fit: cover;
    }
    .content {
      padding-top: 66px;
      padding-left: 48px;
      padding-right: 48px;
      h3 {
        margin: 0;
        font-size: 24px;
        color: #222;
        line-height: 35px;
      }
      p {
        margin: 0;
        padding-top: 24px;
        font-size: 16px;
        color: #3d3d3d;
        line-height: 32px;
      }
    }
  }
}
</style>