<template>
  <div class="reservation">
    <div class="intro">
      <img src="../assets/reservation/bg.png" alt="">
      <div class="title">点滴灵犀</div>
      <h5>一键开启心旅程，打造心理咨询新空间</h5>
      <div class="type">
        <span>危机干预体系</span>危机干预体系，预警-处理-报备-干预
      </div>
      <div class="msg">发现问题到解决问题，全方向的助力心理工作者实现线上化工作。</div>
      <div class="type">
        <span>心理服务支持</span>打造“一站式”心理服务体系
      </div>
      <div class="msg">提供心理咨询、报告解读、心理指导、专家讲座，提升心理工作效率。</div>
      <div class="type"><span>心理健康档案</span>始终如一的为用户建立完善的心理健康档案</div>
      <div class="msg">多维度跟踪成员心理健康健康，为组织心理健康工作提供准确的数据支撑。</div>
    </div>
    <div class="content">
      <h2>业务咨询/预约演示</h2>
      <h6>即刻开启点滴灵犀，开启心旅程</h6>
      <div class="form">
        <div class="name">
          <span><i>*</i>联系人</span>
          <el-input v-model="name" placeholder="请输入联系人姓名" />
        </div>
        <div class="name">
          <span><i>*</i>联系电话</span>
          <el-input v-model="mobile" placeholder="请输入联系人手机号" />
        </div>
        <div class="name">
          <span><i>*</i>单位</span>
          <el-input v-model="company" placeholder="请输入您的公司名称" />
        </div>
        <div class="name">
          <span><i>*</i>您想了解</span>
          <el-select v-model="plan" placeholder="请选择">
            <el-option
              v-for="item in planList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="submitBtn" @click="submitInfo()">提交</div>
      </div>
      <div class="txt">
        <span>表单提交后，会有工作人员与您电话联系</span>
        <span>如果您现在就需要帮助，可立即拨打<i>18516854707</i>与我们联系！</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      plan: "",
      company: "",
      mobile: "",
      name: "",
      planList: [
        "政企解决方案",
        "教育解决方案",
        "医疗解决方案",
        // "政府解决方案",
        // "培训解决方案",
        // "生活解决方案",
      ],
    };
  },
  methods:{

    submitInfo() {
      if (this.name == "") {
        this.$message.error("请输入联系人");
      } else if (this.mobile == "" || this.mobile.length != 11) {
        this.$message.error("请输入正确格式的联系方式");
      } else if (this.company == "") {
        this.$message.error("请输入您的单位名称");
      } else if (this.plan == "") {
        this.$message.error("请选择您想了解的内容");
      } else {
        let formData = new FormData();
        //此处需要和后端接收的参数名相同
        formData.append("name", this.name);
        formData.append("phone", this.mobile);
        formData.append("company", this.company);
        formData.append("content", this.plan);
        this.$axios
          .post(
            //后端接口，自行修改
            "https://demo.lingximind.com/admin_api/ow/submit",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$message.success(
                "提交成功，请耐心等待工作人员与您电话联系。"
              );
            }
          });
      }
    },
  }
};
</script>
<style lang="less" scoped>
.reservation {
  display: flex;
  padding-top: 58px;
  .content {
    flex: 1;
    padding-top: 102px;
    margin: 0 auto;
    h2{
      font-size: 36px;
      line-height: 52px;
      color: #002236;
      font-weight: 350;
      text-align: center;
      margin: 0 0 6px;
    }
    h6{
      line-height: 23px;
      font-size: 16px;
      text-align: center;
      margin: 0;
      font-weight: 400;
      color: #677177;
    }
    .form {
      margin: 38px auto 300px;
      width: 450px;
      div.name {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        span {
          display: inline-block;
          height: 40px;
          width: 87px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          color: #434d53;
          font-size: 14px;
          i {
            font-style: normal;
            color: #fe4141;
            font-size: 16px;
            margin-right: 6px;
          }
        }
        .el-input,
        .el-select,
        .el-select .el-input.el-input--suffix {
          flex: 1;
          height: 40px;
          border-color: #dbe9f1;
          border-radius: 4px;
        }
      }

      .submitBtn {
        width: 436px;
        margin-left: 14px;
        cursor: pointer;
        margin-top: 33px;
        background: #0065ff;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
        border-radius: 4px 4px 4px 4px;
        font-size: 16px;
        text-align: center;
        color: #fff;
        line-height: 44px;
      }
    }
    .txt{
      padding-bottom: 172px;
      span{
        display: block;
        text-align: center;
        font-size: 12px;
        line-height: 19px;
        margin-bottom: 6px;
        color: #999;
        i{
          color: #4A8FB8;
          font-style: normal;
        }
      }
    }
  }
}
.intro {
  width: 40%;
  flex-shrink: 0;
  margin-top: -58px;
  background: linear-gradient(180deg, #F4FBFF 63%, rgba(244,251,255,0) 100%);
  position: relative;
  padding-top: 195px;
  box-sizing: border-box;
  img{
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .title{
    position: relative;
    font-size: 32px;
    line-height: 46px;
    max-width: 480px;
    margin: 0 auto 6px;
  }
  h5{
    font-size: 20px;
    max-width: 480px;
    line-height: 29px;
    position: relative;
    color: #677177;
    margin: 0 auto;
    padding-bottom: 6px;
    font-weight: 350;
  }
  .type{
    display: flex;
    max-width: 480px;
    align-items: center;
    position: relative;
    color: #033654;
    margin: 39px auto 0;
    font-size: 20px;
    line-height: 29px;
    span{
      border: 1px solid #44AEB8;
      text-align: center;
      width: 86px;
      margin-right: 8px;
      font-size: 12px;
      display: block;
      border-radius: 4px;
      height: 22px;
      line-height: 22px;
      color: #44AEB8;
    }
  }
  .msg{
    font-size: 14px;
    position: relative;
    max-width: 480px;
    line-height: 20px;
    color: #798A94;
    margin: 5px auto 0;
  }
}
</style>