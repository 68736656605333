<template>
  <div class="group">
    <div class="banner">
      <div class="bannerContainer">
        <div class="left">
          <div class="txt">情绪压力筛查及危机预警系统</div>
          <p>
            <span
              >生成医疗级团体筛查报告与个人报告。根据监测报告提供个性化的建议和干预方案与心理服务转介。</span
            >
          </p>
          <div class="banner-btn" @click="toPage('reservation')">
            <em>立即预约演示</em>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/product/group/banner-right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="groupContainer">
      <div class="top">
        <h3>我们的优势</h3>
        <div class="intro">科技、普惠、保障型心理健康服务体系</div>
        <div class="lists">
          <dl>
            <dt><img src="../../assets/product/group/icon1.png" alt="" /></dt>
            <dd>
              <p>专业性</p>
              <span
                >由专业的心理学家和北师大心理研究院设计和开发，具有高度的科学性和准确性</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/group/icon2.png" alt="" /></dt>
            <dd>
              <p>高效性</p>
              <span
                >能够快速地对大小团体进行心理健康状态筛查和预警，节省时间和成本</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/group/icon3.png" alt="" /></dt>
            <dd>
              <p>安全性</p>
              <span
                >保证了数据的安全性和隐私性，遵守相关的法律法规和医疗相关原则</span
              >
            </dd>
          </dl>
          <dl>
            <dt><img src="../../assets/product/group/icon4.png" alt="" /></dt>
            <dd>
              <p>实用性</p>
              <span>适用于各种场景和需求，如政企、教育、社区、医疗等</span>
            </dd>
          </dl>
        </div>
      </div>
      <div class="bottom">
        <h3>产品功能与特点</h3>
        <div class="product-container">
          <div class="left">
            <h6>情绪压力筛查</h6>
            <div class="ability">
              利用心理情绪监测可穿戴设备数据采集，分析受测团体的生理体征和心理情绪状态，完成情绪压力筛查。
            </div>
            <div :class="activeIndex1 == 0 ? 'listClick list' : 'list'" @click="activeIndex1 = 0">
              <div>
                <img src="../../assets/product/group/icon-list1.png" alt="" />
                数据驱动与分析
              </div>
              <p>生成详细的心理健康报告，追踪心理健康状况的变化和进展。</p>
            </div>
            <div :class="activeIndex1 == 1 ? 'listClick list' : 'list'" @click="activeIndex1 = 1">
              <div>
                <img src="../../assets/product/group/icon-list1.png" alt="" />
                人工智能技术
              </div>
              <p>生成详细的心理健康报告，追踪心理健康状况的变化和进展。</p>
            </div>
            <div :class="activeIndex1 == 2 ? 'listClick list' : 'list'" @click="activeIndex1 = 2">
              <div>
                <img src="../../assets/product/group/icon-list1.png" alt="" />
                医疗级报告
              </div>
              <p>生成详细的心理健康报告，追踪心理健康状况的变化和进展。</p>
            </div>
          </div>
          <div class="right">
            <img style="width: 596px;" src="../../assets/product/group/list1.png" alt="" />
          </div>
        </div>

        <div class="product-container">
          <div class="right" style="padding-top: 49px">
            <img style="width: 580px;" src="../../assets/product/group/list2.png" alt="" />
          </div>
          <div class="left">
            <h6>实时监测与干预</h6>
            <div class="ability">
              智能系统持续监测用户的心理状态，一旦发现异常，会实时预警并且支持后续干预等。用户可以随时查看心理健康进展，与专业心理健康师在线互动。
            </div>
            <div :class="activeIndex2 == 0 ? 'listClick list' : 'list'" @click="activeIndex2 = 0">
              <div>
                <img src="../../assets/product/group/icon-list1.png" alt="" />
                数据驱动与分析
              </div>
              <p>生成详细的心理健康报告，追踪心理健康状况的变化和进展。</p>
            </div>
            <div :class="activeIndex2 == 1 ? 'listClick list' : 'list'" @click="activeIndex2 = 1">
              <div>
                <img src="../../assets/product/group/icon-list1.png" alt="" />
                隐私与安全保障
              </div>
              <p>生成详细的心理健康报告，追踪心理健康状况的变化和进展。</p>
            </div>
            <div :class="activeIndex2 == 2 ? 'listClick list' : 'list'" @click="activeIndex2 = 2">
              <div>
                <img src="../../assets/product/group/icon-list1.png" alt="" />
                专业合作伙伴
              </div>
              <p>生成详细的心理健康报告，追踪心理健康状况的变化和进展。</p>
            </div>
          </div>
        </div>

        <div class="product-container">
          <div class="left">
            <h6>定制化部署</h6>
            <div class="ability">
              根据公司的需求，平台可以进行定制化部署，适配不同的人员规模和心理健康管理流程。
            </div>
            <div :class="activeIndex3 == 0 ? 'listClick list' : 'list'" @click="activeIndex3 = 0">
              <div>
                <img src="../../assets/product/group/icon-list1.png" alt="" />
                角色功能
              </div>
              <p>生成详细的心理健康报告，追踪心理健康状况的变化和进展。</p>
            </div>
            <div :class="activeIndex3 == 1 ? 'listClick list' : 'list'" @click="activeIndex3 = 1">
              <div>
                <img src="../../assets/product/group/icon-list1.png" alt="" />
                角色成员
              </div>
              <p>生成详细的心理健康报告，追踪心理健康状况的变化和进展。</p>
            </div>
            <div :class="activeIndex3 == 2 ? 'listClick list' : 'list'" @click="activeIndex3 = 2">
              <div>
                <img src="../../assets/product/group/icon-list1.png" alt="" />
                部门分级
              </div>
              <p>生成详细的心理健康报告，追踪心理健康状况的变化和进展。</p>
            </div>
          </div>
          <div class="right" style="padding-top: 84px">
            <img style="width: 544px;" src="../../assets/product/group/list3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex1:0,
      activeIndex2:0,
      activeIndex3:0,
    };
  },
  methods: {
    downApp() {
      this.$refs.downapp.scrollIntoView({
        behavior: "smooth",
      });
    },
    toPage(name) {
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false;
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
    height: 70px;
  }
  100% {
    height: 148px;
    opacity: 1;
  }
}
.banner {
  background: url(../../assets/about/banner.png) no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  height: 500px;
  position: relative;
  margin-top: -58px;
  overflow: hidden;
  .bannerContainer {
    width: 1180px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    .left {
      width: 580px;
      flex-shrink: 0;
      .txt {
        font-size: 44px;
        line-height: 64px;
        color: #222;
        position: relative;
        margin: auto;
        font-weight: 400;
        padding-top: 138px;
      }

      p {
        font-size: 16px;
        line-height: 23px;
        margin: 24px auto 0;

        span {
          display: block;
          width: 521px;
          color: #222;
        }
      }

      .banner-btn {
        position: absolute;
        bottom: 87px;
        em {
          display: block;
          font-style: normal;
          cursor: pointer;
          background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
          width: 190px;
          height: 52px;
          border-radius: 30px;
          text-align: center;
          line-height: 52px;
          color: #0065ff;
          font-size: 16px;
        }
      }
    }
    .right {
      padding-top: 102px;
      img {
        width: 485px;
        height: 362px;
      }
    }
  }
}
.groupContainer {
  background: url(../../assets/product/bg.png) no-repeat;
  background-size: 100% auto;
  background-position-y: top;
  .top {
    padding: 84px 0 80px;
    width: 1180px;
    margin: 0 auto;
    .intro {
      color: #798a94;
      line-height: 29px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 80px;
    }
    .lists {
      display: flex;
      dl {
        flex: 1;
        margin: 0 95px 0 0;
        text-align: center;
        dt {
          width: 50px;
          height: 50px;
          margin: 0 auto;
          img {
            float: left;
            width: 100%;
            height: 100%;
          }
        }
        dd {
          margin: 0;
          p {
            margin: 14px 0 9px;
            text-align: center;
            font-size: 20px;
            line-height: 29px;
            color: #3d3d3d;
          }
          span {
            display: block;
            line-height: 20px;
            text-align: left;
            color: #3d3d3d;
            font-size: 14px;
          }
        }
      }
      dl:last-child {
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 34px;
    color: #011739;
    line-height: 49px;
    margin: 0 0 12px;
    text-align: center;
  }
  .bottom {
    text-align: center;
    padding: 84px 0 80px;
    background: rgba(0, 101, 255, 0.06);
    .product-container {
      display: flex;
      max-width: 1180px;
      margin: 40px auto 80px;
      justify-content: space-between;
      height: 564px;
      .left {
        width: 452px;
        flex-shrink: 0;
        text-align: left;
        h6 {
          font-size: 34px;
          line-height: 49px;
          font-weight: 500;
          color: #011739;
          margin: 0 0 23px;
        }
        .ability {
          color: #798a94;
          font-size: 20px;
          line-height: 36px;
          margin-bottom: 72px;
          font-weight: 400;
        }
        .list {
          height: 70px;
          width: 444px;
          overflow: hidden;
          border-radius: 8px;
          margin-bottom: 12px;
          box-sizing: border-box;
          cursor: pointer;
          div {
            background: #fff;
            padding: 0 24px;
            display: flex;
            height: 70px;
            align-items: center;
            font-size: 20px;
            img {
              width: 43px;
              height: 40px;
              margin-right: 21px;
            }
          }
          p {
            margin: 3px 0 0;
            padding-left: 88px;
            font-size: 18px;
            text-align: left;
            padding-right: 32px;
            color: #8aa0c0;
          }
        }
        .listClick {
          background: #e0eaff;
          animation: fadeIn 0.3s;
          height: 148px;
          div{
            background: #e0eaff;
          }
        }
      }
      .right{
        padding-top: 110px;
      }
    }
     .product-container:last-child{
      margin-bottom: 0;
     }
  }
}
</style>