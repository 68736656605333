<template>
  <div class="education">
    <div class="banner">
      <div class="txt">教育应用场景</div>
      <p>
        <span
          >为基础教育、职业教育及高等教育机构，提供简单可依赖的心理健康教育服务，帮助教育机构持续减负增效</span
        >
      </p>
      <div class="banner-btn" @click="toPage('reservation')"><em>立即预约演示</em></div>
    </div>
    <div
      class="tabs"
      v-show="tabIndex != 4"
      :style="
        scrollNum > 417
          ? 'position:fixed;top:0px;width:100%;z-index:2'
          : 'position:relative'
      "
    >
      <div :style="scrollNum > 417 ? 'top:0;width:100%' : ''">
        <p
          @click="clickItemBtn('conRef1')"
          :class="tabIndex == 0 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 0 ? 'background:#0065FF;color:#fff' : ''"
        >
          方案优势
        </p>
        <p
          @click="clickItemBtn('conRef2')"
          :class="tabIndex == 1 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 1 ? 'background:#0065FF;color:#fff' : ''"
        >
          解决方案介绍
        </p>
        <p
          @click="clickItemBtn('conRef3')"
          :class="tabIndex == 2 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 2 ? 'background:#0065FF;color:#fff' : ''"
        >
          解决方案全景
        </p>
        <p
          @click="clickItemBtn('conRef4')"
          :class="tabIndex == 3 ? 'active' : ''"
          :style="scrollNum > 417 && tabIndex== 3 ? 'background:#0065FF;color:#fff' : ''"
        >
          客户案例
        </p>
      </div>
    </div>
    <div class="tabMenu1" :style="scrollNum > 417 ? 'padding-top:60px' : ''" ref="conRef1">
      <div class="bigBox">
        <div class="title">方案优势</div>
      <div class="intro">让心理健康成为互联网心动力</div>
      <div class="slide">
        <p
          v-for="(item, index) in slideList"
          :key="index"
          @click="setCurrent(index, 'tab')"
          :class="slideIndex == index ? 'active' : ''"
        >
          {{ item.title }}
        </p>
      </div>
      <div class="slideIntro">
        <el-carousel
          ref="carousel"
          :autoplay="false"
          height="454px"
          @change="getCurrent"
          indicator-position="none"
          arrow="never"
        >
          <el-carousel-item v-for="item in slideList" :key="item.name">
            <img :src="item.img" alt="" srcset="" />
            <div class="content">
              <h3>{{ item.title }}</h3>
              <p>
                {{ item.intro }}
              </p>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="slideBtn">
          <div class="last" @click="setCurrent(-1, 'btn')">&lt;</div>
          <div class="next" @click="setCurrent(1, 'btn')">&gt;</div>
        </div>
      </div>
      </div>
      
    </div>
    <div class="tabMenu2" ref="conRef2">
      <div class="title" v-animate="'move-up'">解决方案介绍</div>
      <div class="intro" v-animate="'move-up'">响应国家要求及时为学生解决、预防心理问题</div>
      <div class="menu2Container">
        <div class="text">
          <p>
            从2019年12月18日健康中国行动《儿童青少年心理健康行动方案（2019-2022年）》到2022年4月27日国务院办公厅印发“十四五”国民健康规划通知。需要建设一套集数据化、无纸化、大数据整合全新的心理危机干预平台，实现针对青少年心理健康现象，集合国内外多位知名专家，专门针对青少年心理健康打造了心理危机干预平台，从专业性、保密性多个方向进行青少年心理筛查、疏导工作，增加心理干预，专业心理辅导老师一对一进行疏导。从社会、家庭、校园多方位普及心理干预知识，将心理危机干预普及到每一个需要关注的孩子身上。从根本上解决由校园欺凌、情感纠纷、师生矛盾、学业压力、家庭矛盾给孩子带来的心理健康问题。
          </p>
        </div>
      </div>
    </div>
    <div class="tabMenu3" ref="conRef3">
      <div class="title" v-animate="'move-up'">解决方案全景</div>
      <div class="intro" v-animate="'move-up'">架构完善，实现更复杂的场景问题解决</div>
      <div class="menu3Container">
        <div class="top">
          <div class="left">
            <div>
              <img src="../../assets/solution/icon-menu.png" alt="" />
              <p>解决方案</p>
            </div>
          </div>
          <div class="right">
            <dl>
              <dt>政企解决方案</dt>
              <dd>司法丨军工丨交通</dd>
            </dl>
            <dl>
              <dt>教育解决方案</dt>
              <dd>K12院校丨幼儿园丨泛教育丨教育局</dd>
            </dl>
            <dl>
              <dt>医疗解决方案</dt>
              <dd>互联网医院丨医疗结构丨体检机构</dd>
            </dl>
          </div>
        </div>
        <div class="center">
          <div class="left">
            <div>
              <img src="../../assets/solution/icon-menu.png" alt="" />
              <p>应用平台</p>
            </div>
          </div>
          <div class="right">
            <dl>
              <dt>心理情绪监测系统</dt>
              <dd>
                <p>公有云/私有云</p>
                <p>混合云部署</p>
              </dd>
              <dd>
                <p>运维保障</p>
                <p>定制开发</p>
              </dd>
            </dl>

            <dl>
              <dt>数据可视化监控平台</dt>
              <dd>
                <p>多源异构数据融合</p>
              </dd>
              <dd>
                <p>大数据统计分析</p>
                <p>定制开发</p>
              </dd>
            </dl>
            <dl>
              <dt>心态预警与评估系统</dt>
              <dd>
                <p>风险等级预警</p>
              </dd>
              <dd>
                <p>智能短信</p>
                <p>智能外呼</p>
              </dd>
            </dl>

            <dl>
              <dt>心理服务站信息化</dt>
              <dd>
                <p>智能舒心仓</p>
                <p>可视化数据大屏</p>
              </dd>
              <dd>
                <p>放松椅</p>
                <p>智能化空间建设</p>
              </dd>
            </dl>

            <dl>
              <dt>幸福心联服务平台</dt>
              <dd>
                <p>心理需求发布</p>
                <p>心理人才在线培训</p>
              </dd>
              <dd>
                <p>在线心理服务</p>
                <p>心理人才考试系统</p>
              </dd>
            </dl>

            <dl>
              <dt>Seemo(犀陌)移动端</dt>
              <dd>
                <p>健康体征监测</p>
                <p>社区通讯</p>
              </dd>
              <dd>
                <p>在线心理咨询</p>
                <p>电商</p>
              </dd>
            </dl>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            <div>
              <img src="../../assets/solution/icon-menu.png" alt="" />
              <p>底层服务</p>
            </div>
          </div>
          <div class="right">
            <div class="rightTop">
              <p>云原生</p>
              <p>心理情绪AI算法</p>
              <p>大数据</p>
              <p>AIGC</p>
              <p>边缘计算</p>
              <p>物联网</p>
            </div>
            <div class="rightBottom">
              <p>计算服务</p>
              <p>储存服务</p>
              <p>网络服务</p>
              <p>基础服务</p>
              <p>硬件服务</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabMenu4"
     ref="conRef4">
      <div class="title" v-animate="'move-up'">客户案例</div>
      <div class="intro" v-animate="'move-up'">专属数据解决方案与服务，伴随客户持续成长</div>
      <div class="menu4Container">
        <dl>
          <dt><img src="../../assets/solution/education/news1.png" alt="" /></dt>
          <dd>
            <p class="name">北京月坛第一幼儿园</p>
            <p class="msg">
              北京月坛第一幼儿园在心理健康方面的优势和特色主要有以下几个方面。首先，幼儿园有着专业的师资团队，可以为幼儿提供专业的心理咨询和心理辅导。其次，幼儿园在心理健康方面有着全面的服务，包括心理咨询、心理辅导、心理培训等多个方面。最后，幼儿园设计的心理健康活动非常多元化，包括各种心理游戏、心理讲座、心理干预等活动，以满足不同幼儿的需求。
            </p>
            <div class="type">
              <span>教育</span>
            </div>
          </dd>
        </dl>
        <dl>
          <dt><img src="../../assets/solution/education/news2.png" alt="" /></dt>
          <dd>
            <p class="name">浙江师范大学</p>
            <p class="msg">
              浙江师范大学心理学院将在心理健康领域继续发挥其学科优势，为国家的心理健康事业做出更大的贡献。该学院将继续加强心理学科建设，提高教学质量和科研水平，开展更多心理健康活动，为更多的人提供优质的心理健康服务。
            </p>
            <div class="type">
              <span>教育</span>
            </div>
          </dd>
        </dl>
        <dl>
          <dt><img src="../../assets/solution/education/news3.png" alt="" /></dt>
          <dd>
            <p class="name">北京培智中心学校</p>
            <p class="msg">
              北京培智中心学校在致力于提升学生教育质量的同时，也十分注重心理健康方面的教育和支持。学校的专业心理咨询和治疗、心理培训和讲座、家长工作和多样化的实践案例等都充分展示了学校对学生的关心和支持。
            </p>
            <div class="type">
              <span>教育</span>
            </div>
          </dd>
        </dl>
      </div>
      <div class="menu4Number">
        <dl>
          <dt>13417<span>+</span></dt>
          <dd>项目广泛应用</dd>
        </dl>
        <dl>
          <dt>237<span>+</span></dt>
          <dd>客户共同选择</dd>
        </dl>
        <dl>
          <dt>1234578<span>+</span></dt>
          <dd>每日活跃用户</dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabIndex: 0,
      slideIndex: 0,
      slideList: [
        {
          title: "普测普筛——发现问题",
          img: require("../../assets/solution/education/swiper1.png"),
          intro:
            "侧重于团体普测，团体筛查。作为高效化心理筛查的全新工具和全国独有技术，Lxemotion情绪手环被广泛应用于学生、职工、居民、体检人员的心理筛查评估中。参评人员只需戴上情绪手环，边上课或者边工作，20分钟即可完成筛查评估，团体报告及个人报告自动生成，管理者直观看到需要关注的人群及存在的问题。目前心理讲座同步开展情绪手环团体测评的方式也受到各学校及单位的欢迎和广泛采用。",
        },
        {
          title: "预警处理——解决问题",
          img: require("../../assets/solution/education/swiper2.png"),
          intro:
            "通过普测普筛可以筛查出有严重心理问题的人员，对校方、教育局推送预警信息，实现校方、教育局掌握学生情况，布置安排及活动规划。",
        },
        {
          title: "心理服务——干预调节",
          img: require("../../assets/solution/education/swiper3.png"),
          intro:
            "通过普测普筛及预警信息，为校方、教育局提供8000+社会心理指导人才实现校方心理需求发布发布平台为校方、教育局匹配服务人才，实现线下线上心理健康课堂等；同时为学生家长提供seemo（犀陌）app，实现家长及时关注学生身心健康体征状态，满足家、校联合关注学生健康成长。",
        },
      ],
      scrollNum:0,
    };
  },
  mounted(){
    window.addEventListener('scroll',this.handleScroll,true)
  },
  methods: {
    handleScroll() {
      this.scrollNum = document.documentElement.scrollTop;
      if (this.scrollNum < 1230) {
        this.tabIndex = 0;
      } else if (this.scrollNum >= 1230 && this.scrollNum < 2120) {
        this.tabIndex = 1;
      } else if (this.scrollNum >= 2120 && this.scrollNum < 3095) {
        this.tabIndex = 2;
      } else if (this.scrollNum >= 3085 && this.scrollNum < 4070) {
      // } else if (this.scrollNum >= 3085 && this.scrollNum < 3580) {
        this.tabIndex = 3;
      } else {
        this.tabIndex = 4;
      }
    },

    toPage(name){
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
    getCurrent(e) {},

    setCurrent(type, name) {
      if (name == "btn") {
        if (type == 1) {
          this.slideIndex == this.slideList.length - 1
            ? (this.slideIndex = 0)
            : (this.slideIndex += 1);
        } else {
          this.slideIndex == 0
            ? (this.slideIndex = this.slideList.length - 1)
            : (this.slideIndex -= 1);
        }
      } else {
        this.slideIndex = type;
      }

      this.$refs.carousel.setActiveItem(this.slideIndex);
    },

    clickItemBtn(index) /* 点击按钮触发 */ {
      if (index == "conRef1") {
        this.$refs.conRef1.scrollIntoView({
          behavior: "smooth",
        });
        setTimeout(()=>{
          this.tabIndex = 0;
        },500)
        
      } else if (index == "conRef2") {
        this.$refs.conRef2.scrollIntoView({
          behavior: "smooth",
        });
        setTimeout(()=>{
          this.tabIndex = 1;
        },500)
        
      } else if (index == "conRef3") {
        this.$refs.conRef3.scrollIntoView({
          behavior: "smooth",
        });
        setTimeout(()=>{
          this.tabIndex = 2;
        },500)
        
      } else {
        this.$refs.conRef4.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setTimeout(()=>{
          this.tabIndex = 3;
        },500)
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./common.less";
.banner {
  height: 500px;
  background: url(../../assets/solution/education/banner.png) no-repeat;
  background-size: auto 100%;
  background-position-y: bottom;
  margin-top: -58px;
  background-position-x: center;
}

.menu2Container {
  height: 613px;
  background: url(../../assets/solution/education/bg.png) no-repeat;
  background-size: auto 100%;
  background-position-x: center;
}
</style>
<style lang='less'>
.solution {
  .el-carousel__item {
    display: flex;
    background: #fff;
    // box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);
    img {
      width: 590px;
      height: 454px;
      object-fit: cover;
    }
    .content {
      padding-top: 66px;
      padding-left: 48px;
      padding-right: 48px;
      h3 {
        margin: 0;
        font-size: 24px;
        color: #222;
        line-height: 35px;
      }
      p {
        margin: 0;
        padding-top: 24px;
        font-size: 16px;
        color: #3d3d3d;
        line-height: 32px;
      }
    }
  }
}
</style>