<template>
  <div :class="windowWidth >= 1180 ? 'home' : 'home small'">
    <div class="swiper">
      <el-carousel
        ref="carousel"
        :height="windowWidth >= 1180 ? '558px' : '300px;'"
        @change="getCurrent"
        indicator-position="none"
        arrow="never"
      >
        <el-carousel-item>
          <img src="../assets/home/banner1.jpg" alt="" srcset="" />
          <div class="txt">
            <h3>科技温暖人心</h3>
            <p>
              为企业提供整体解决方案，帮助企业搭建心理驿站、专兼职心理服务队伍、心理服务体系，助力建设社会心理服务体系。
            </p>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/home/banner2.jpg" alt="" srcset="" />
          <div class="txt">
            <h3>产品多样化</h3>
            <p>
              适用于大小团体，可以为企业、学校、机构等提供心理健康状态的筛查和预警服务。
            </p>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/home/banner3.jpg" alt="" srcset="" />
          <div class="txt">
            <h3>幸福心联</h3>
            <p>
              公益免费的心理健康信息化平台,提供多元化心理科普、自助化心理测评、在线化调节训练、系统化教育培训，让心理科普日常化、心理调节简单化
            </p>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="banner-btn" @click="toPage('reservation')">
        <p>立即预约演示</p>
      </div>
      <div class="dots">
        <div class="line">
          <span @mouseenter="setCurrent(0)" class="blue"></span>
          <span
            @mouseenter="setCurrent(1)"
            :class="currentIndex > 0 ? 'blue' : ''"
          ></span>
          <span
            @mouseenter="setCurrent(2)"
            :class="currentIndex > 1 ? 'blue' : ''"
          ></span>
        </div>
        <p :class="currentIndex == 0 ? 'bigC' : ''">1</p>
        <p :class="currentIndex == 1 ? 'bigC' : ''">2</p>
        <p :class="currentIndex == 2 ? 'bigC' : ''">3</p>
      </div>
    </div>
    <div class="product">
      <div class="title">我们的优势</div>
      <h5 style="padding: 0 140px">点滴灵犀解决方案运用物联网、云计算等前沿技术，通过云平台、智能设备及咨询服务等一体化服务， 为社会心理服务信息化平台建设和心态数据监测提供专业服务商支持。</h5>
      <div class="productLists">
        <dl>
          <dt>
            <img src="../assets/home/logo-cdsb.png" alt="" />
            科技化的筛查工具
          </dt>
          <dd>
            <div class="name">情绪状态监测与分析的科研产品</div>
            <p>
              通过PPG精密光电手腕采集器收集并处理生理数据，观测波峰之间间隔长短变化特点，分析自主神经变化情况，采用HRV和压力综合指标分析人的情绪、心理状态，通过大数据AI情绪压力综合分析模型与危机预警模型，筛查出需要关注的人群。
            </p>
            <!-- <div class="lookInfo" @click="toPage('productMonitor')">
              了解产品
            </div> -->
          </dd>
        </dl>
        <dl>
          <dt>
            <img src="../assets/home/logo-tub.png" alt="" />
            自主知识产权
          </dt>
          <dd>
            <div class="name">拥有多个荣誉以及国家级认证</div>
            <p>
              国家发明专利3+、国家高新技术企业、中关村高新技术企业、“十四五”高等教育教材建设项目、北京市社会心理工作联合会理事单位、社会心理指导师+专项人才培养示范基地。
            </p>
            <!-- <div class="lookInfo" @click="toPage('productGroup')">了解产品</div> -->
          </dd>
        </dl>
        <dl>
          <dt>
            <img src="../assets/home/logo-seemo.png" alt="" />
            高效的筛查能力
          </dt>
          <dd>
            <div class="name">3000+日检人次</div>
            <p>
              应对大规模的心理筛查场景，点滴灵犀拥有强大、高效、准确的筛查能力，可以应对日常检测超过3000人次的筛查场景。
            </p>
            <!-- <div class="lookInfo" @click="toPage('productSEEMO')">了解产品</div> -->
          </dd>
        </dl>
        <dl>
          <dt>
            <img src="../assets/home/logo-qxb.png" alt="" />
            数字化的报告
          </dt>
          <dd>
            <div class="name">专业的报告分析</div>
            <p>
              我们的算法团队来自于国际/国内各大心理学名校及专家团队， Lxemotion情绪手环经由医院及其他机构测评，心跳间期(毫秒级)误差仅有5%，心率动态测量误差在2pbm左右，静态误差在5pbm；其中心率变异性、压力指数、情绪心境特征、身心能量和情绪心境特征等心理指标均符合使用标准。
            </p>
            <!-- <div class="lookInfo" @click="toPage('productQxb')">了解产品</div> -->
          </dd>
        </dl>
        <dl>
          <dt>
            <img src="../assets/home/logo-xfxl.png" alt="" />
            高规格的专家研发团队
          </dt>
          <dd>
            <div class="name">算法团队来自于各大心理学名校及专家团队</div>
            <p>
              国际级专家20多位，国家级专家100多位，社会服务心理人才10000多位，其中包含“十四五”高等教育教材建设项目合作专家，该教材将面向心理学和计算机专业的本科生。预计于2024年由高等教育出版社出版。
            </p>
            <!-- <div class="lookInfo" @click="toPage('productScreen')">
              了解产品
            </div> -->
          </dd>
        </dl>
        <dl>
          <dt>
            <img src="../assets/home/logo-xlfw.png" alt="" />
            高效的数据处理能力
          </dt>
          <dd>
            <div class="name">在线心理服务、倾述吐槽等服务平台</div>
            <p>
              幸福心联心理服务平台是一个专业的心理健康平台，旨在为广大用户提供全方位的心理健康科普、服务、咨询等内容。
            </p>
            <div class="lookInfo" @click="toPage('productXFXL')">了解产品</div>
          </dd>
        </dl>
      </div>
    </div>
    <div class="tabs">
      <div class="title">全面、专业、完善的一站式解决方案</div>
      <h5>
        专注为企业提供心理解决方案、TI运维服务以及向软件承包商提供软件分包服务
      </h5>
      <div class="tabsBox">
        <p
          @click="
            tabIndex = 0;
            contentIndex = 0;
          "
          :class="tabIndex == 0 ? 'active' : ''"
        >
          政企
        </p>
        <p
          @click="
            tabIndex = 1;
            contentIndex = 0;
          "
          :class="tabIndex == 1 ? 'active' : ''"
        >
          教育
        </p>
        <p
          @click="
            tabIndex = 2;
            contentIndex = 0;
          "
          :class="tabIndex == 2 ? 'active' : ''"
        >
          医疗
        </p>
      </div>
      <div class="tabsMain" v-if="tabIndex == 0">
        <img src="../assets/home/zq-bg.jpg" alt="" />
        <div class="content">
          <div class="left">
            <p
              @click="setContent(index)"
              v-for="(item, index) in contentLists"
              :key="index"
              :class="contentIndex == index ? 'active' : ''"
            >
              {{ item.name }}
            </p>
          </div>
          <div class="right">
            <div class="name">
              涵盖场景：<span>{{
                contentLists[contentIndex].values.type
              }}</span>
            </div>
            <p class="intro">{{ contentLists[contentIndex].values.intro }}</p>
            <div class="contentProduct">
              <div class="type">相关产品</div>
              <div class="contentProductName">
                <p
                  @click="toPage(name.route)"
                  v-for="(name, ind) in contentLists[contentIndex].values.list"
                  :key="ind + 100"
                >
                  {{ name.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabsMain tabsMainjy" v-if="tabIndex == 1">
        <img src="../assets/home/jy-bg.jpg" alt="" />
        <div class="content">
          <div class="left">
            <p
              @click="setContent(index)"
              v-for="(item, index) in contentLists1"
              :key="index"
              :class="contentIndex == index ? 'active' : ''"
            >
              {{ item.name }}
            </p>
          </div>
          <div class="right">
            <div class="name">
              涵盖场景：<span>{{
                contentLists[contentIndex].values.type
              }}</span>
            </div>
            <p class="intro">{{ contentLists[contentIndex].values.intro }}</p>
            <div class="contentProduct">
              <div class="type">相关产品</div>
              <div class="contentProductName">
                <p
                  @click="toPage(name.route)"
                  v-for="(name, ind) in contentLists[contentIndex].values.list"
                  :key="ind + 100"
                >
                  {{ name.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabsMain tabsMainyl" v-if="tabIndex == 2">
        <img src="../assets/home/yl-bg.jpg" alt="" />
        <div class="content">
          <div class="left">
            <p
              @click="setContent(index)"
              v-for="(item, index) in contentLists2"
              :key="index"
              :class="contentIndex == index ? 'active' : ''"
            >
              {{ item.name }}
            </p>
          </div>
          <div class="right">
            <div class="name">
              涵盖场景：<span>{{
                contentLists[contentIndex].values.type
              }}</span>
            </div>
            <p class="intro">{{ contentLists[contentIndex].values.intro }}</p>
            <div class="contentProduct">
              <div class="type">相关产品</div>
              <div class="contentProductName">
                <p
                  @click="toPage(name.route)"
                  v-for="(name, ind) in contentLists[contentIndex].values.list"
                  :key="ind + 100"
                >
                  {{ name.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="friend">
      <div class="title">合作伙伴</div>
      <h5>良好的产品和完善的服务体系为客户保驾护航</h5>
      <img src="../assets/home/company.png" alt="" />
    </div>
    <!-- <div class="choose">
      <div class="title">为什么选择点滴灵犀</div>
      <h5>
        点滴灵犀解决方案打造云平台+智能设备+咨询服务等“一站式”服务体系，借助物联网、云计算、大数据、人工智能等前沿科研技术，以专业的服务团队，标准化服务流程，智慧化管理平台，提供有智能可穿戴情绪分析、人脸情绪监测等技术，是多地社会心理服务信息化平台建设和社会心态数据监测的服务商、提供商。
      </h5>
    </div> -->
    <div class="teacher">
      <div class="title">专家人才</div>
      <h5>每一位认真做心理的人都值得被认真对待</h5>
      <div class="teacherInfo">
        <img :src="teacherList[teacherIndex].BigImg" alt="" class="left" />
        <div class="right">
          <div class="tabImg">
            <!-- <img src="@/static/teacher-zrc.png" alt=""> -->
            <div
              :class="teacherIndex == index ? 'active' : ''"
              :key="index"
              v-for="(item, index) in teacherList"
              @click="teacherIndex = index"
            >
              <img :src="item.headImg" alt="" />
              <i></i>
            </div>
          </div>
          <div class="name">{{ teacherList[teacherIndex].name }}</div>
          <div class="intro">{{ teacherList[teacherIndex].intro }}</div>
        </div>
      </div>
    </div>
    <div class="news">
      <div class="title">点滴最新动态</div>
      <h5>不忘初心，方得始终，持续为客户创造价值</h5>
      <div class="newsList">
        <dl v-for="item in newsList" :key="item.id">
          <dt class="times">
            <span>{{ item.year }}</span>
            <p>
              {{ item.month }}/<i>{{ item.day }}</i>
            </p>
          </dt>
          <dd>
            <div class="line"></div>
            <div class="newscontent">
              <div class="newsTitle">{{ item.title }}</div>
              <div class="newsMsg">{{ item.msg }}</div>
            </div>
            <div class="cover">
              <img :src="item.cover" alt="" />
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      windowWidth: "",
      currentIndex: 0,
      tabIndex: 0,
      contentIndex: 0,
      contentLists: [
        {
          name: "中华全国总工会",
          values: {
            type: "情绪测评 心理疏导 心理减压",
            intro:
              "为全总职工提供情绪测评、心理减压、心理疏导服务。 2020 年 1 月，中华全国总工会“灵犀小屋”落成，打造工会体系样板工程，立足于用显性化的专业设备、便捷化的管理工具、针对性的实用技巧有效调节职工的心理压力和情绪状态，从而完善社会支持系统、提升职工的自我心理弹性、保持积极向上的心态品质。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
              {
                name: "情绪宝APP",
                route: "productQxb",
              },
            ],
          },
        },
        {
          name: "国家体育总局",
          values: {
            type: "情绪测评 情绪分析",
            intro:
              "为国家体育总局运动员提供测评、观察、定制分析服务。2019年6月开始， Lxemotion情绪手环系统对运动员情绪状态与比赛成绩的影响进行分析，根据分析报告，专家团队对不同运动员开展相对应的训练，助力运动员在比赛时取得好成绩。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "情绪宝APP",
                route: "productQxb",
              },
              {
                name: "幸福心联平台",
                route: "productScreen",
              },
            ],
          },
        },
        {
          name: "北京市民政局",
          values: {
            type: "情绪测评 心理疏导 心理减压",
            intro:
              "为北京社区心理服务的主要工作者进行心理测评及相关培训，从而使社会心理服务站能够更便捷地为居民提供心理测评，更有效地为居民开展团体辅导活动。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "Seemo（犀陌）APP",
                route: "productSEEMO",
              },
              {
                name: "情绪宝APP",
                route: "productQxb",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
            ],
          },
        },
        {
          name: "徐工集团",
          values: {
            type: "情绪测评 心理讲座",
            intro:
              "徐工集团与北京点滴灵犀科技有限公司共同打造职工心理健康服务体系，从心理健康测评、心理危机预警系统建设、心理健康讲座、心理技能培训、心理团体辅导、个案心理疏导、心理空间建设等多方面为职工提供心理健康一体化服务",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
            ],
          },
        },
        {
          name: "中交集团",
          values: {
            type: "情绪测评 日常科普",
            intro:
              "中交集团2021年3月起建设企业社会心理服务中心，打造从日常科普、心理测评、自我心理调整、心理预防、社会心理服务培训，到个案疏导形成多方位一体化的企业心理健康平台，为员工提供普及性、普遍性、普惠性的心理健康服务。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "Seemo（犀陌）APP",
                route: "productSEEMO",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
            ],
          },
        },
      ],
      contentLists1: [
        {
          name: "福建平和一中",
          values: {
            type: "情绪测评 心理档案建设",
            intro:
              "2020年12月7日，福建省平和第一中学开展学生心理健康档案建立、心理危机预警系统建设、情绪测评及情绪健康小课堂系列活动。本次活动当天共为高三年级20个班级近千名学生建立心理健康档案。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
              {
                name: "情绪宝APP",
                route: "productQxb",
              },
            ],
          },
        },
        {
          name: "北京月坛第一幼儿园",
          values: {
            type: "情绪测评 心理讲座",
            intro:
              "2020年12月16日，月坛第一幼儿园全体职工开展心理健康培训活动。本次心理培训活动邀请到全军“汶川”抗震救灾心理服务专家、现任北京市民政局副巡视员张青之教授为大家带来“知行合一，做好情绪管理”的主题讲座。讲座的同时，讲座课堂同步开展Lxemotion情绪测评及报告解读活动。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
              {
                name: "幸福心联平台",
                route: "productScreen",
              },
            ],
          },
        },
        {
          name: "福建漳州康桥学校",
          values: {
            type: "情绪测评 情绪筛查",
            intro:
              "2019年4月福建省漳州市康桥学校采用Lxemotion情绪手环+智能量表双数据筛查的全新模式，针对即将参加高考的411名高三学生开展心理健康测评活动和趣味团体心理拓展活动。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "Seemo（犀陌）APP",
                route: "productSEEMO",
              },
              {
                name: "情绪宝APP",
                route: "productQxb",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
            ],
          },
        },
        {
          name: "北京西城培智中心学校",
          values: {
            type: "情绪测评 心理档案建设",
            intro:
              "西城培智中心学校高度重视心理健康教育工作，2019年便打造了智慧心理危机预警平台，引进了点滴灵犀Lxemotion情绪手环为师生提供智能化的心理普测普筛服务，构建了心态监测体系，建设了师生心理健康档案系统。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
            ],
          },
        },
        {
          name: "徐州荆山中心小学",
          values: {
            type: "情绪测评 心理档案建设",
            intro:
              "徐州荆山中心小学2021年引进点滴灵犀Lxemotion情绪手环，并开展教师心理健康培训，通过普测普筛了解和掌握师生心理健康状态，通过培训教师阳光心态，大大地提升了学校心理教育工作水平，有效预防了危机事件的发生。图为2021年4月22日，徐州荆山小学面向全体教师开展情绪管理培训及情绪测评活动。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "Seemo（犀陌）APP",
                route: "productSEEMO",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
            ],
          },
        },
      ],
      contentLists2: [
        {
          name: "广西南宁第二妇幼医院",
          values: {
            type: "情绪测评 心理档案建设",
            intro:
              "广西南宁市第二妇幼保健院采购点滴灵犀情绪压力危机预警系统及Lxemotion情绪手环，在南宁市中小学校和社区开展“关爱生命·从心开始”少年儿童心理健康公益普查活动--情绪压力筛查及危机预警测评活动。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
              {
                name: "情绪宝APP",
                route: "productQxb",
              },
            ],
          },
        },
        {
          name: "武汉长江航运总医院",
          values: {
            type: "情绪测评 评估训练",
            intro:
              "在2020年新冠肺炎防控中，武汉长江航运总医院是武汉市第一批发热门诊定点医院，医护人员承受着较大的精神压力，社心理援助基金向武汉长江航运总医院捐赠了100套点滴灵犀（Lxemotion）情绪手环。通过科技和专业的设备系统有效的对医护人员进行评估和训练。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
              {
                name: "心理服务咨询平台",
                route: "productXFXL",
              },
              {
                name: "情绪宝APP",
                route: "productQxb",
              },
            ],
          },
        },
        {
          name: "成都市精神卫生中心",
          values: {
            type: "情绪测评 情绪筛查",
            intro:
              "为成都市精神卫生中心提供系列心理测评、讲座、培训、心理疏导等服务。 帮助卫生中心矫正人员建立积极的人生观、价值观、世界观，并帮助他们找到自己的获得感、归宿感、幸福感。",
            list: [
              {
                name: "心理情绪监测可穿戴设备",
                route: "productMonitor",
              },
              {
                name: "情绪压力筛查及危机系统",
                route: "productGroup",
              },
            ],
          },
        },
      ],
      teacherIndex: 0,
      teacherList: [
        {
          name: "郑日昌",
          headImg: require("../assets/home/teacher-zrc.png"),
          BigImg: require("../assets/home/teacher-zrc-b.png"),
          intro:
            "北京师范大学教授、博士生导师，中国心理卫生协会常务理事，全国大学生心理咨询委员会副主任，中国社会心理学会常务理事，教育部考试中心兼职研究员，教育部中小学心理健康教育咨询委员会副主任，教育部普通高等学校学生心理健康教育专家指导委员会委员，全国大学生心理咨询委员会副主任，中国人才素质测评研究会副会长，北京高校心理咨询研究会理事长。",
        },
        {
          name: "贾福军",
          headImg: require("../assets/home/teacher-hjj.png"),
          BigImg: require("../assets/home/teacher-hjj-b.png"),
          intro:
            "全国学生心理健康工作咨询委员会委员，广东省人民医院精神卫生研究所所长，精神病学教授，美国MINUCHIN心理治疗基金会高级班核心组成员，中国睡眠研究会理事，睡眠与心理障碍专业委员会主任委员，中国心理卫生协会理事，心理治疗与心理咨询专业委员会委员，《中华精神科杂志》及《国外医学精神病学分册》等杂志编委",
        },
        {
          name: "牟航",
          headImg: require("../assets/home/teacher-mh.png"),
          BigImg: require("../assets/home/teacher-mh-b.png"),
          intro:
            "清华大学生物科学与技术系博士、生命科学学院博士后。浙江清华长三角研究院生物医药产业化中心副主任，华园健康教育研究院副院长主持/参加国家重大科学仪器设备开发专项（子任务）、国家自然科学基金青年科学基金项目、国家自然科学基金面上项目、浙江省自然科学基金面上项目、浙江省科技计划项目等10余项科研项目，在国际期刊上发表SCI论文10余篇，参与编写高校教材1部。授权发明/实用新型专利7项。入选浙江省重点科技创新团队成员，杭州市“131”中青年人才培养计划人选。",
        },
        {
          name: "王真真",
          headImg: require("../assets/home/teacher-mh.png"),
          BigImg: require("../assets/home/teacher-mh-b.png"),
          intro:
            "南部战区空军医院神经内分泌科主任，广东省医学会神经病学分会委员，广东省康复医学会神经康复专业委员会委员，全军军事卫生心理学专业委员会委员，广州军区医学心理学专业委员会副主任委员，广东省医学会行为和心身医学分会委员，广东省第12、13、14届人大代表",
        },

        {
          name: "田素梅",
          headImg: require("../assets/home/teacher-hjj.png"),
          BigImg: require("../assets/home/teacher-hjj-b.png"),
          intro:
            "安徽医科大学济民肿瘤医学中心临床心理科主任，中国抗癌协会肿瘤心理专委会常务委员，中国癌症基金会肿瘤心理协作组常务委员，中国抗癌协会中西整合卵巢癌专业委员会委员，安徽省抗癌协会肿瘤心理专委会副主任委员，安徽省健康服务业协会乳腺分会常务委员，安徽省传统医学专业委员会常务委员，安徽省医师协会整合医学分会委员，安徽省医学心理学会副主任委员",
        },
        // {
        //   name: "张久祥",
        //   headImg: require("../assets/home/teacher-zjx.jpg"),
        //   BigImg: require("../assets/home/teacher-zjx-b.png"),
        //   intro:
        //     "CCTV-12《心理访谈》常驻心理专家；国内最具影响力的实战派心理咨询专家之一；参与录制中央电视台《心理访谈》、《生活早参考》、《健康之路》等节目750余期；著有《犯罪心理与案例分析》、《关注成长走进孩子的心灵》、《听，谁的创伤在说话》等。",
        // },

        {
          name: "何江军",
          headImg: require("../assets/home/teacher-hjj.png"),
          BigImg: require("../assets/home/teacher-hjj-b.png"),
          intro:
            "成都市精神卫生中心心理测评中心主任，北京市社会心理工作联合会心理测评专业委员会副秘书长，心理咨询师、心理治疗师、心理危机干预顾问，发表论文10余篇，主持及参与国家级、省、市科研项目20余项，参与编译心理著作7部。参与国家863项目、5.12震后心理危机干预、卫生部/英国DFID灾后心理援助、国家自然科学基金相关项目研究测评，及国家疾控中心核事故应急救援人员危机干预方案编制等工作。曾为应急管理部四川消防研究所、富士康、华为等开展心理健康相关服务。",
        },
        // {
        //   name: "张斌",
        //   headImg: require("../assets/home/teacher-zb.png"),
        //   BigImg: require("../assets/home/teacher-zb-b.png"),
        //   intro:
        //     "上海复旦大学医学博士，具有二十多年临床经验，北京市社会心理工 作联合会心理测评专业委员会常务委 员，杭州电视台西湖明珠频道情绪健 康节目《有情世界》特邀情绪健康专 家，著有《情绪交响乐》。",
        // },
      ],
      newsList: [
        {
          id: 1,
          year: "2023",
          month: "11",
          day: "20",
          title: "江西省开展AI心理测评打造学生心理健康保障型心理服务体系",
          msg: "近日，江西省教育厅社区教育服务中心协同育人试点工作在修水县第二中学、第三中学、第八小学、杭口小学、良塘中心幼儿园等校开展了师生心理健康普测普筛活动，本次试点工作的测评对象涵盖小学1-6年级，初中7-9年级，",
          cover: require("../assets/home/news1.png"),
        },
        {
          id: 2,
          year: "2023",
          month: "12",
          day: "20",
          title: "夏日炎炎，漳州长运集团开展这项活动",
          msg: "本活动情绪状态筛查采用全新科技智能的Lxemotion情绪手环筛查形式，快速简便开展全员普测工作，参评人员只需佩戴情绪手环，几分钟后便可智能生成情绪状态报告，报告主要分析司机的心理健康状况和情绪压力水平，系统同时生成全员心理健康状态报告，将突出问题进行分类，以便后续开展有针对性的团体辅导及必要的个案咨询。",
          cover: require("../assets/home/news2.png"),
        },
        {
          id: 3,
          year: "2023",
          month: "12",
          day: "20",
          title: "宜昌市高新区教育局成功举办智慧心理测评系统应用培训",
          msg: "5月10日，由宜昌市高新区教育局主办的“智慧心理测评系统应用培训”活动在区毓秀路小学成功举办，来自区教育局相关负责人和全区各中小学校专职心理健康教育教师参加了本次系统应用培训活动。本次培训活动，点滴灵犀通过",
          cover: require("../assets/home/news3.png"),
        },
        {
          id: 4,
          year: "2023",
          month: "09",
          day: "20",
          title: "第四期心理测评专项能力培训圆满落幕",
          msg: "10月16日，第4期心理测评专项能力培训班在北京顺利举办，本次培训班邀请到北京师范大学著名心理学家郑日昌教授、北京师范大学心理健康教育与咨询中心常务副主任宋振韶博士等为大家授课，线上线下共41名学员参与了学习，大家一致表示，此次培训学习收获颇丰。",
          cover: require("../assets/home/news4.png"),
        },
      ],
      currentScroll: "",
      testShow: false,
    };
  },
  created() {
    this.windowWidth = window.document.documentElement.clientWidth;
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll); //？监听浏览器滚动条滚动
  },
  methods: {
    // handleScroll() {
    // this.currentScroll = window.pageYOffset; //表示当前滚动的位置
    // console.log(this.currentScroll, "curr");
    // if (this.currentScroll > this.$refs.title1.offsetTop - 100) {
    //   //当前滚动位置到达testref的时候，显示div（100作为调整用）
    //   this.testShow = true;
    // }
    // if (this.currentScroll > this.$refs.testref2.offsetTop - 100) {
    //   this.testShow2 = true;
    // }
    // },
    getCurrent(e) {
      this.currentIndex = e;
    },
    setCurrent(index) {
      this.currentIndex = index;
      this.$refs.carousel.setActiveItem(index);
    },
    setContent(index) {
      this.contentIndex = index;
    },
    toPage(name) {
      if (name == "home") {
        this.$router.push({
          path: "/",
        });
        return false;
      }
      this.$router.push({
        path: `/${name}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
  }
}
.home {
  .swiper {
    box-sizing: border-box;
    margin-top: -58px;
    position: relative;
    .txt {
      width: 1180px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 138px;
      h3 {
        font-size: 44px;
        line-height: 64px;
        color: #222;
        font-weight: 400;
        margin: 0 0 24px;
      }
      p {
        width: 520px;
        font-size: 16px;
        line-height: 23px;
        color: #222;
      }
    }
    .banner-btn {
      position: absolute;
      bottom: 172px;
      cursor: pointer;
      width: 1180px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      p {
        background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
        width: 190px;
        height: 52px;
        margin: 0;
        border-radius: 30px;
        text-align: center;
        line-height: 52px;
        color: #0065ff;
        font-size: 16px;
      }
    }
    .dots {
      position: absolute;
      width: 1180px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100px;
      align-items: flex-end;
      display: flex;
      .line {
        flex-shrink: 0;
        padding-bottom: 8px;
        span {
          display: inline-block;
          height: 4px;
          cursor: pointer;
          width: 105px;
          background: #e4e4e4;
        }
        span.blue {
          background: #0065ff;
        }
      }
      p {
        margin: 0;
        line-height: 31px;
        font-size: 22px;
        color: #6a7171;
        font-weight: 400;
        width: 35px;
        margin: 0 23px;
      }
      p.bigC {
        color: #0065ff;
        line-height: 50px;
        font-size: 60px;
        font-weight: 400;
      }
    }
  }
  .title {
    font-size: 34px;
    line-height: 49px;
    color: #011739;
    font-weight: 700;
    margin: 0 0 12px;
    text-align: center;
  }
  h5 {
    font-size: 20px;
    color: #798a94;
    font-weight: 400;
    text-align: center;
    margin: 0;
    line-break: 29px;
  }
  .product {
    width: 1180px;
    margin: auto;
    padding: 84px 0 84px;
    .productLists {
      margin-top: 62px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      dl {
        margin: 0 0 24px;
        box-shadow: 0px 0px 8px 0px rgba(74, 143, 184, 0.2);
        padding: 19px 0 0;
        width: 377px;
        height: 320px;
        position: relative;
        dt {
          display: flex;
          align-items: center;
          font-size: 18px;
          margin: 0 26px;
          line-height: 36px;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(0,  0, 0, 0.1);
          img {
            height: 36px;
            margin-right: 12px;
            width: 36px;
          }
        }
        dd {
          margin: 0;
          padding: 16px 45px 0 26px;
          .name {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 9px;
            color: #282d37;
          }
          p {
            margin: 0;
            font-size: 14px;
            line-height: 20px;
          }
          .lookInfo {
            position: absolute;
            bottom: 0;
            cursor: pointer;
            width: 100%;
            height: 49px;
            left: 0;
            font-size: 16px;
            line-height: 49px;
            color: #0065ff;
            text-align: center;
            background: rgba(0, 101, 255, 0.2);
          }
          .lookInfo:hover {
            background: rgba(109, 166, 254, 0.2);
          }
        }
      }
    }
  }
  .tabs {
    position: relative;
    height: 932px;
    width: 100%;
    overflow-x: hidden;
    padding-top: 84px;
    box-sizing: border-box;
    .title {
      position: relative;
      color: #f0faff;
      z-index: 2;
    }
    h5 {
      color: #ddeaf2;
      position: relative;
      z-index: 2;
    }
    .tabsBox {
      width: 1180px;
      margin: 62px auto 0;
      position: relative;
      z-index: 2;
      height: 67px;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      p {
        width: 136px;
        line-height: 67px;
        margin: 0;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        text-align: center;
      }
      p.active {
        color: #61eefb;
        border-bottom: 1px solid #61eefb;
        box-sizing: border-box;
      }
    }
    .tabsMain {
      // opacity: 0;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 932px;
        width: 1920px;
        object-fit: cover;
      }
      .content {
        position: relative;
        display: flex;
        width: 1180px;
        margin: 48px auto 0;
        .left {
          flex-shrink: 0;
          width: 276px;
          margin-right: 72px;
          background: url(../assets/home/zqTabLeft.png) no-repeat 100%;
          p {
            margin: 0;
            height: 97px;
            font-size: 20px;
            color: #fff;
            line-height: 97px;
            text-align: center;
            cursor: pointer;
            position: relative;
          }
          .active {
            background: linear-gradient(90deg, #4a8fb8 0%, #3ebacb 100%);
            filter: blur(0px);
          }
          p:hover {
            background: rgba(0, 0, 0, 0.4);
          }
          p:hover:after {
            filter: blur(0px);
            display: none;
          }
          p.active:hover {
            background: linear-gradient(90deg, #4a8fb8 0%, #3ebacb 100%);
            filter: blur(0px);
          }
        }
        .right {
          flex: 1;
          padding-top: 6px;
          color: #fff;
          box-sizing: border-box;
          .name {
            font-size: 24px;
            line-height: 35px;
            span {
              color: #61eefb;
            }
          }
          .intro {
            font-size: 16px;
            line-height: 23px;
            margin: 12px 0 0;
            padding-bottom: 36px;
            border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
          }
          .contentProduct {
            margin-top: 46px;
            .type {
              font-size: 20px;
              line-height: 29px;
              margin-bottom: 44px;
            }
            .contentProductName {
              display: flex;
              flex-wrap: wrap;
              p {
                width: 255px;
                height: 54px;
                margin: 0 33px 34px 0;
                // border: 1px solid #e6e6e6;
                text-align: center;
                line-height: 54px;
                box-sizing: border-box;
                font-size: 16px;
                position: relative;
                cursor: pointer;
              }
              p:nth-child(1) {
                background: url(../assets/home/zqbg1.png) no-repeat 100%;
              }
              p:nth-child(2) {
                background: url(../assets/home/zqbg2.png) no-repeat 100%;
              }
              p:nth-child(3) {
                background: url(../assets/home/zqbg3.png) no-repeat 100%;
              }
              p:nth-child(4) {
                background: url(../assets/home/zqbg4.png) no-repeat 100%;
              }
              p:nth-child(5) {
                background: url(../assets/home/zqbg5.png) no-repeat 100%;
              }
              p:nth-child(3n) {
                margin-right: 0;
              }
              p:hover {
                // filter: blur(0px);
                border: 1px solid #e6e6e6;
                box-sizing: border-box;
                background: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
      }
    }
    .tabsMain.tabsMainjy {
      .left {
        background: url(../assets/home/jyTabLeft.png) no-repeat 100%;
      }
      .right {
        .contentProduct {
          .contentProductName {
            p:nth-child(1) {
              background: url(../assets/home/jybg1.png) no-repeat 100%;
            }
            p:nth-child(2) {
              background: url(../assets/home/jybg2.png) no-repeat 100%;
            }
            p:nth-child(3) {
              background: url(../assets/home/jybg3.png) no-repeat 100%;
            }
            p:nth-child(4) {
              background: url(../assets/home/jybg4.png) no-repeat 100%;
            }
            p:nth-child(5) {
              background: url(../assets/home/jybg5.png) no-repeat 100%;
            }
              p:hover {
                // filter: blur(0px);
                border: 1px solid #e6e6e6;
                box-sizing: border-box;
                background: rgba(0, 0, 0, 0.3);
              }
          }
        }
      }
    }
    .tabsMain.tabsMainyl {
      .left {
        height: 291px;
        background: url(../assets/home/ylTabLeft.png) no-repeat 100%;
      }
      .right {
        .contentProduct {
          .contentProductName {
            p:nth-child(1) {
              background: url(../assets/home/ylbg1.png) no-repeat 100%;
            }
            p:nth-child(2) {
              background: url(../assets/home/ylbg2.png) no-repeat 100%;
            }
            p:nth-child(3) {
              background: url(../assets/home/ylbg3.png) no-repeat 100%;
            }
            p:nth-child(4) {
              background: url(../assets/home/ylbg4.png) no-repeat 100%;
            }
            p:nth-child(5) {
              background: url(../assets/home/ylbg5.png) no-repeat 100%;
            }
              p:hover {
                // filter: blur(0px);
                border: 1px solid #e6e6e6;
                box-sizing: border-box;
                background: rgba(0, 0, 0, 0.3);
              }
          }
        }
      }
    }
    .tabsFadeIn {
      animation: fadeIn 3s;
      opacity: 1;
    }
    .tabsFadeOut {
      animation: fadeOut 3s;
      display: none;
    }
  }
  .friend {
    text-align: center;
    padding: 84px 0 82px;
    img {
      width: 89%;
      margin: 60px auto 0;
    }
  }
  .choose {
    background: url(../assets/home/choose-bg.png) no-repeat #f4fbff;
    background-size: 100% auto;
    background-position-y: bottom;
    height: 1002px;
    width: 100%;
    padding-top: 84px;
    box-sizing: border-box;
    h5 {
      width: 952px;
      text-align: left;
      margin: auto;
    }
  }
  .teacher {
    padding: 84px 0 84px;
    .teacherInfo {
      box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.1);
      max-width: 1180px;
      margin: 40px auto 0;
      padding: 24px 255px 72px 36px;
      box-sizing: border-box;
      display: flex;
      .left {
        width: 241px;
        height: 360px;
        object-fit: cover;
        flex-shrink: 0;
      }
      .right {
        padding-top: 58px;
        margin-left: 36px;
        flex: 1;
        .tabImg {
          display: flex;
          margin-bottom: 36px;
          div {
            width: 82px;
            position: relative;
            height: 82px;
            margin-right: 24px;
            i {
              position: absolute;
              display: block;
              width: 82px;
              height: 82px;
              border-radius: 10px;
              background: rgba(255, 255, 255, 0.5);
              top: 0;
              cursor: pointer;
              left: 0;
            }
          }
          img {
            width: 82px;
            height: 82px;
            object-fit: scale-down;
            margin-right: 24px;
            border-radius: 10px;
          }
          div.active {
            i {
              opacity: 0;
            }
          }
          img:last-child {
            margin-right: 0;
          }
        }
        .name {
          color: #011739;
          font-size: 30px;
          font-weight: 400;
          line-height: 43px;
        }
        .intro {
          font-size: 16px;
          line-height: 26px;
          color: #6a7171;
          margin-top: 12px;
        }
      }
    }
  }
  .news {
    padding: 84px 0 84px;
    .newsList {
      max-width: 1180px;
      margin: 36px auto 0;
      dl {
        margin: 0;
        padding: 24px 0 28px;
        border-bottom: 1px solid rgba(74, 143, 184, 0.2);
        display: flex;
        .times {
          width: 156px;
          flex-shrink: 0;
          display: flex;
          flex-wrap: wrap;
          span {
            padding-top: 4px;
            font-size: 20px;
            color: #3d3d3d;
            line-height: 29px;
          }
          p {
            margin: 14px 0 0;
            font-size: 50px;
            line-height: 70px;
            color: #011739;
            i {
              color: #0065ff;
              font-style: normal;
              font-size: 50px;
              line-height: 70px;
            }
          }
        }
        dd {
          margin: 0;
          display: flex;
          flex: 1;
          .line {
            width: 70px;
            margin-top: 18px;
            height: 1px;
            flex-shrink: 0;
            background: rgba(74, 143, 184, 0.2);
          }
          .newscontent {
            margin-left: 16px;
            flex: 1;
            margin-right: 54px;
            .newsTitle {
              font-size: 24px;
              line-height: 35px;
              color: #3d3d3d;
              margin-bottom: 20px;
              overflow: hidden;
              height: 35px;
              text-overflow: ellipsis;
            }
            .newsMsg {
              height: 52px;
              font-size: 14px;
              line-height: 26px;
              overflow: hidden;
              color: #6a7171;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
          }
          .cover {
            width: 182px;
            height: 110px;
            flex-shrink: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
.small {
  .swiper {
    position: relative;
    img {
      height: 300px;
      width: 100%;
      object-fit: cover;
    }
    .txt {
      width: 90%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 30px;
      h3 {
        font-size: 22px;
        line-height: 32px;
        color: #222;
        font-weight: 400;
        margin: 0 0 12px;
      }
      p {
        width: 90%;
        font-size: 12px;
        line-height: 23px;
        color: #222;
      }
    }
    .banner-btn {
      position: absolute;
      top: 180px;
      cursor: pointer;
      z-index: 22;
      width: 80%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      p {
        background: linear-gradient(90deg, #d6d3ff 0%, #bce0ff 100%);
        width: 190px;
        height: 52px;
        border-radius: 30px;
        text-align: center;
        line-height: 52px;
        color: #0065ff;
        font-size: 16px;
      }
    }
    .dots {
      display: none;
    }
  }
  .title {
    font-size: 17px;
    line-height: 20px;
  }
  h5 {
    font-size: 12px;
  }
  .product {
    width: 100%;
    padding: 40px 0 40px;
    .productLists {
      padding-left: 14px;
      padding-right: 14px;
      display: flex;
      flex-wrap: wrap;
      .left {
        width: 100%;
        dl {
          width: 100%;
          height: 350px;
        }
      }
      .right {
        dl {
          width: 100%;
          height: 350px;
        }
      }
    }
  }
}
</style>